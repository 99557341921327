<template>
    <div class="main">
        <section class="section blog-details-wrapper">
            <div class="container">
                <transition name="fade">
                    <loader v-if="loading" :text="loader_text"  />
                    <div v-else-if="!loading && !err" class="row">
                        <div class="card profile-card">
                            <div class="content">
                                <div class="photo">
                                    <img :src="'/app-assets/images/services/'+images" alt="Transaction logo">
                                </div>
                                <div class="my-4">
                                    <p>Total Payment</p>
                                    <h3 class="name text-center">{{ transaction.currency.symbol+transaction.amount }}</h3>
                                </div>
                                <table class="table table-borderless kundli-table" cellpadding="10">
                                    <tbody>
                                        <tr>
                                            <td>Type</td>
                                            <td class="text-capitalize">{{ transaction.type }}</td>
                                        </tr>
                                        <tr>
                                            <td>Reason</td>
                                            <td class="text-capitalize">{{ transaction.sub_type?.replace('_', ' ') }}</td>
                                        </tr>
                                        <tr v-if="transaction.type == 'credit'">
                                            <td>Payment ID</td>
                                            <td class="text-capitalize">{{ transaction.payment_id }}</td>
                                        </tr>
                                        <tr>
                                            <td>Transaction On</td>
                                            <td class="text-capitalize">{{ $filters.timestamp(transaction.created_at) }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <router-link :to="url" class="btn dark">View Record</router-link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="!loading && err">
                        <div class="col-12 col-lg-8 offset-lg-2">
                            <div class="card">
                                <div class="text-center row match-height">
                                    <div class="col-lg-6 spin-mandala ty">
                                        <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                        <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                    </div>
                                    <div class="col-lg-6">
                                        <img src="/app-assets/images/avatar.png" alt="Mandala">
                                    </div>
                                </div>
                                <p class="desc">
                                    Oops, {{ err }}.<br><br>Please try again later.
                                </p>
                                <div class="flex-cc">
                                    <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props: ['user'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            id: '',
            transaction: null,
            errors: [],
            err: null,
            loading: false,
            disBtn: false,
            loader_text: 'Loading . . .',
        };
    },
    created() {
        this.emitter.emit('talk_section', true)
        this.emitter.emit('blog_section', true)

        if(this.token) {
            this.id = this.$route.params?.id
            this.index()
        } else this.loading = true
    },
    computed: {
        url () {
            switch (this.transaction?.sub_type) {
                case 'kundli' : return '/kundli/'+ this.transaction?.sub_crypt +'/result' ;
                case 'kundli_pdf' : return '/kundli' ;
                case 'matchmaking' : return '/kundli-matchmaking/'+ this.transaction?.sub_crypt +'/result' ;
                case 'matchmaking_pdf' : return '/kundli-matchmaking' ;
                case 'rudraksh_gemstone' : return '/rudraksh-gemstone/'+ this.transaction?.sub_crypt +'/result' ;
                case 'numerology' : return '/numerology/'+ this.transaction?.sub_crypt +'/result' ;
                case 'chat' : return '/' ;
                case 'audio_call' : return '/' ;
                case 'puja_booking' : return '/booking-detail/'+this.transaction?.sub_crypt ;
                default: break;
            }
        },
        images() {
            switch (this.transaction?.sub_type) {
                case 'kundli' : return 'kundli.png' ;
                case 'kundli_pdf' : return 'kundli.png' ;
                case 'matchmaking' : return 'kundli-matchmaking.png' ;
                case 'matchmaking_pdf' : return 'kundli-matchmaking.png' ;
                case 'rudraksh_gemstone' : return 'rudraksh-gemstone.png' ;
                case 'numerology' : return 'numerology.png' ;
                case 'chat' : return 'chat.png' ;
                case 'audio_call' : return 'audio_call.png' ;
                case 'video_call' : return 'video_call.png' ;
                case 'puja_booking' : return 'astro-pujas.png' ;
                case null : return 'money_add.png' ;
                default: break;
            }
        },
    },
    beforeUnmount() {
        this.emitter.emit('talk_section', false)
        this.emitter.emit('blog_section', false)
    },
    methods: {
        index(flag = false) {
            if(!flag) this.loading = true
            this.$Progress.start()
            axios.get('/api/user/transaction-details/get/'+this.id, this.config).then((res) => {
                if(!flag) {
                    this.loading = false
                    this.emitter.emit('scroll_section')
                }
                if(res.data.status == 'success') {
                    this.transaction = res.data.transaction
                    this.$Progress.finish()
                } else {
                    this.err = res.data.message
                    this.$Progress.fail()
                }

            }).catch((error) => {
                toast.fire({'icon': 'error', 'title': 'Server Error Occured!'})
                // this.$router.push('/my-dastrologers')
                if(!flag) this.loading = false
            })
        },
    }
}
</script>