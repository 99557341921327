<template>
    <div class="puja-card">
        <router-link :to="'/'+this.type+'/'+puja?.slug" class="img-wrapper">
            <img :src="puja?.banner" :alt="'Banner - '+ puja?.title">
            <div class="category">{{ puja?.tags?.splice(0,3).join(',') }}</div>
        </router-link>
        <div class="content">
            <router-link :to="'/'+this.type+'/'+puja?.slug" class="puja-title">
                {{ puja?.title }}
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    props: ['puja', 'type'],
    data() {
        return {

        };
    },
}
</script>