<template>
    <div class="scholar-landing-page">
        <section class="section orange" ref="section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <h1>Let's Begin Your Journey!</h1>
                        <h3>Join {{ $appname }} as Astrologer</h3>
                        <router-link to="/scholar-registration" class="btn orange-light">Start Registration</router-link>
                    </div>
                    <div class="col-lg-4">
                        <div class="spin-mandala" :class="{'small' : mobile}">
                            <img src="/app-assets/images/mandala.png" alt="Login Mandala" class="mandala">
                            <img src="/app-assets/images/avatar.png" alt="Login Avatar" class="avatar">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section dark-orange">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-8 offset-lg-2 text-center">
                        <h3 class="cta-title">Why Join {{ $appname }}?</h3>
                        <p class="cta-desc">Welcome to {{ $appname }} - where a unique spiritual knowledge journey begins. Join us as an astrologer and share your astrological insights with a vibrant community.<br><br>{{ $appname }} is a platform designed for pandits, offering a space to connect, collaborate, and explore new knowledge. Join us to enhance your studies, connect with fellow scholars, and share your expertise with others.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section orange">
            <div class="container">
                <div class="row match-height">
                    <div class="col-12 col-md-6 col-lg-3">
                        <div class="feature-card">
                            <i class="fad fa-om"></i>
                            <div class="title">Expert Guidance</div>
                            <div class="desc">Receive personalized mentorship from industry experts to elevate your scholarly endeavors.</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <div class="feature-card">
                            <i class="fad fa-users"></i>
                            <div class="title">Collaborative Community</div>
                            <div class="desc">Engage with a vibrant community of scholars, fostering meaningful connections and collaborative learning.</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <div class="feature-card">
                            <i class="fad fa-books"></i>
                            <div class="title">Resourceful Library</div>
                            <div class="desc">Engage with a vibrant community of scholars, fostering meaningful connections and collaborative learning.</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <div class="feature-card">
                            <i class="fad fa-trophy-star"></i>
                            <div class="title">Recognition & Awards</div>
                            <div class="desc">Be recognized for your academic achievements and contributions with awards and accolades.</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section mid-orange">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <div class="row match-height">
                            <div class="col-12 col-lg-4 card-parent">
                                <div class="process-card color-1">
                                    <span class="step">Step - 1</span>
                                    <div class="title">Fill Up the Application Form</div>
                                    <div class="desc">Begin your journey by completing a simple application form. Share your passion, aspirations, and academic background.</div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 card-parent">
                                <div class="process-card color-2">
                                    <span class="step">Step - 2</span>
                                    <div class="title">Take the Skill Test</div>
                                    <div class="desc">Participate in our skill test multiple times to showcase your abilities. Successful attempts lead you to the next step.</div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 card-parent">
                                <div class="process-card color-3">
                                    <span class="step">Step - 3</span>
                                    <div class="title">Personal Interview Interaction</div>
                                    <div class="desc">After passing the skill test, we invite you for a personal interaction. It's a chance for us to understand you better and ensure the right fit for our community.</div>
                                </div>
                            </div>
                            <div class="col-12 flex-cc pt-5">
                                <router-link to="scholar-registration" class="btn dark">Start Your Application Journey Now</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s ease-in;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
    filter: blur(2px);
}
.collapse-enter-active, .collapse-leave-active {
    transform-origin: top;
    transition: all 0.3s ease-in;
}

.collapse-enter-to, .collapse-leave-from {
    transform: scaleY(1);
    opacity: 1;
    filter: blur(0);
}

.collapse-enter-from, .collapse-leave-to {
    transform: scaleY(0);
    opacity: 0;
    filter: blur(5px);
}
i.fas {
    transition: all 0.2s ease-in ;
}
.flip {
    transform: rotate(180deg);
}
</style>
<script>
export default {
    data() {
        return {
            token: false,
            mobile: this.isMobile(),
        };
    },
    methods: {
    }
}
</script>