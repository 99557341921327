<template>
    <div class="main">
        <section class="section blog-details-wrapper">
            <div class="container">
                <transition name="fade">
                    <loader v-if="loading" :text="loader_text"  />
                    <div v-else-if="!loading && !err" class="row">
                        <div class="col-lg-8 mt-5 mt-sm-0 order-2 order-lg-1">
                            <h4 v-if="mobile" class="mb-4 text-center"><u>Puja Details</u></h4>
                            <div class="blog-header">
                                <div class="img-wrapper">
                                    <img draggable="false" :src="booking.puja.banner" :alt="booking.puja.title+' - Banner'">
                                </div>
                                <h5 class="pb-2">{{ booking.puja.title }}</h5>
                            </div>
                            <div class="puja-description ql-editor" v-html="booking.puja.description"></div>
                        </div>
                        <div class="col-lg-4 order-1 order-lg-2">
                            <div class="sticky-top" style="z-index: 0;">
                                <div class="card profile-card w-100 mt-0" v-if="booking.status == 3 && booking.pandit">
                                    <div class="content">
                                        <div class="photo">
                                            <img :src="booking.pandit?.pandit?.image || '/app-assets/images/h-logo.png'" alt="">
                                        </div>
                                        <div class="name">{{ booking.pandit?.pandit?.name }}, <span class="gender text-capitalize">{{ booking.pandit?.pandit?.gender }}</span></div>
                                        <div class="text-center">
                                            <span class="mobile"><b>{{ booking.pandit?.pandit?.mobile }}</b></span><br>
                                            <span class="email"><b>{{ booking.pandit?.pandit?.email }}</b></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card orange">
                                    <div class="col-12 text-center form-group">
                                        <h4><u>Booking Details</u></h4>
                                        <img src="/app-assets/images/puja-thali.png" class="w-50">
                                    </div>
                                    <form class="row" @submit.prevent="cancel()">
                                        <div class="col-12 form-group orange required">
                                            <label class="mb-2">Date</label>
                                            <input type="text" class="form-control" readonly :value="$filters.date(booking.date) + ' ' + $filters.timeFT(booking.time)">
                                        </div>
                                        <div class="col-12 form-group orange">
                                            <label class="mb-2">Puja Type</label>
                                            <input type="text" class="form-control" readonly :value="booking.type == 'online' ? 'Online Puja' : 'Onsite Puja'">
                                        </div>
                                        <div class="col-12 form-group orange" v-if="booking.type == 'onsite'">
                                            <label>Puja Location</label>
                                            <input type="text" class="form-control" readonly :value="booking.location.city">
                                        </div>
                                        <div class="flex-bc gap-3 mb-3">
                                            <label>At Price</label>
                                            <h6>{{ booking.currency_details.symbol+booking.price }} <p class="small-text d-inline" v-if="booking.status == 3">(Deducted)</p></h6>
                                        </div>
                                        <div class="flex-bc gap-3 mb-3">
                                            <label>Status</label>
                                            <h6>{{ status }}</h6>
                                        </div>
                                        <div class="col-12 form-group orange">
                                            <p class="small-text" v-if="booking.status == 0"><b>Note: </b>Any booking will not get cancelled after admin approval. Anyone can cancel the booking only until it is pending admin approval.</p>
                                            <p class="small-text" v-else-if="booking.status == 2"><b>Note: </b>Only admin holds the right to cancel any puja booking. Please contact admin for any query.</p>
                                            <p class="small-text" v-else-if="booking.status == 3"><b>Note: </b>Above mentioned Pandit/Scholar has been assigned by admin. Contact Admin for any query.</p>
                                            <button type="submit" v-if="booking.status == 0" :disabled="disBtn == 1" class="btn dark block mb-2">
                                                <i v-if="disBtn == 1" class="fas fa-spinner fa-spin"></i>
                                                <transition name="flip" mode="out-in">
                                                    <span v-if="!disBtn">Cancel Booking</span>
                                                    <span v-else>Loading . . .</span>
                                                </transition>
                                            </button>
                                            <router-link :to="'/transaction/'+booking.transaction_crypt" class="btn block dark">View Receipt</router-link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="!loading && err">
                        <div class="col-12 col-lg-8 offset-lg-2">
                            <div class="card">
                                <div class="text-center row match-height">
                                    <div class="col-lg-6 spin-mandala ty">
                                        <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                        <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                    </div>
                                    <div class="col-lg-6">
                                        <img src="/app-assets/images/avatar.png" alt="Mandala">
                                    </div>
                                </div>
                                <p class="desc">
                                    Oops, {{ err }}.<br><br>Please try again later.
                                </p>
                                <div class="flex-cc">
                                    <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props: ['user'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            id: '',
            booking: null,
            errors: [],
            err: null,
            loading: false,
            disBtn: false,
            loader_text: 'Loading . . .',
        };
    },
    created() {
        if(this.token) {
            this.id = this.$route.params.id
            this.index()
        } else this.loading = true
    },
    computed: {
        status () {
            if(this.booking.status == 0) return 'Pending' ;
            else if(this.booking.status == 1) return 'Cancelled - By User' ;
            else if(this.booking.status == 2) return 'Cancelled - By Admin' ;
            else if(this.booking.status == 3) return 'Approved' ;
            else if(this.booking.status == 4) return 'Completed' ;
        }
    },
    methods: {
        index(flag = false) {
            if(!flag) this.loading = true
            this.$Progress.start()
            axios.get('/api/user/booking-details/get/'+this.id, this.config).then((res) => {
                if(!flag) {
                    this.loading = false
                    this.emitter.emit('scroll_section')
                }
                if(res.data.status == 'success') {
                    this.booking = res.data.booking
                    this.$Progress.finish()
                } else {
                    this.err = res.data.message
                    this.$Progress.fail()
                }

            }).catch((error) => {
                toast.fire({'icon': 'error', 'title': 'Server Error Occured!'})
                // this.$router.push('/my-bookings')
                if(!flag) this.loading = false
            })
        },
        async cancel() {
            this.disBtn = 1 ;
            await axios.get('/api/user/cancel-booking/'+this.id, this.config).then((res) => {
                this.disBtn = false ;
                if(res.data.status == 'success') {
                    this.index(true)
                }
                else {
                    toast.fire({'icon': 'error', 'title':  res.data.message})
                }
            }).catch((e) => {
                if (e.response.status === 422) this.errors = e.response.data.errors;
                this.disBtn = false ;
                toast.fire({'icon': 'error', 'title': 'Server Error Occured!'})
            })
        },
    }
}
</script>