<template>
    <div class="blog-card">
        <router-link :to="'/blog/'+blog?.slug" class="img-wrapper">
            <img :src="blog?.banner" :alt="'Banner - '+ blog?.title">
        </router-link>
        <div class="content">
            <div class="meta">
                <div class="date"><span>{{ blog?.maker_name }}</span><span>{{ $filters.date(blog?.created_at) }}</span></div>
                <!-- <div class="author">
                    <img :src="(blog?.role == 'admin' ? blog?.admin?.photo : blog?.pandit?.photo) || '/app-assets/images/h-logo.png'" alt="">
                    <span>{{ blog?.maker_name }}</span>
                </div> -->
            </div>
            <router-link :to="'/blog/'+blog?.slug" class="blog-title">
                {{ blog?.title }}
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path d="M7.96094 17.5L17.9609 7.5M17.9609 7.5H7.96094M17.9609 7.5V17.5" stroke="#38210F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </router-link>
            <div class="description">
                {{ blog?.meta_description }}
                <router-link class="more" :to="'/blog/'+blog?.slug">More...</router-link>
            </div>
            <div class="category"><span v-for="r,i in blog?.categories">{{ r }}</span></div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['blog'],
    data() {
        return {

        };
    },
}
</script>