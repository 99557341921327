<template>
    <div class="main">
        <section class="section" ref="section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <transition-group name="scale" mode="out-in" tag="div" class="row match-height">
                            <div class="col-md-6 col-12 col-lg-4" v-for="(p,index) in pujas" :key="index">
                                <puja-card type="puja-booking" :puja="p"></puja-card>
                            </div>
                            <nav key="Page navigation" aria-label="Page navigation" v-if="pujas.length">
                                <ul class="custom-pagination" v-if="pagination?.pageCount > 7">
                                    <li class="page-item prev-item" @click="pageNo = (pageNo > 1) ? pageNo - 1 : pagination?.pageCount"><i class="fas fa-sm fa-long-arrow-left"></i></li>
                                    <li class="page-item" :class="{ 'active' : pageNo==1 }" @click="pageNo=1"><a class="page-link" href="#">1</a></li>
                                <li class="divider" :class="{ 'active' : pageNo > 1 && pageNo < pagination?.pageCount }"></li>
                                    <li class="page-item" :class="{ 'active' : pageNo==pagination?.pageCount }" @click="pageNo = pagination?.pageCount"><a class="page-link" href="#">{{ pagination?.pageCount }}</a></li>
                                    <li class="page-item next-item" @click="pageNo = pageNo < pagination?.pageCount ? pageNo+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                </ul>
                                <ul class="custom-pagination" v-else>
                                    <li class="page-item prev-item" @click="pageNo = (pageNo > 1) ? pageNo - 1 : pagination?.pageCount"><i class="fas fa-long-arrow-left"></i></li>
                                    <li class="page-item" :class="{'active' : pageNo == i}" v-for="i in pagination?.pageCount" @click="pageNo=i" :key="i"><a class="page-link" href="#">{{ i }}</a></li>
                                    <li class="page-item next-item" @click="pageNo = pageNo < pagination?.pageCount ? pageNo+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                </ul>
                            </nav>
                        </transition-group>
                        <transition name="fade">
                            <loader v-if="loading" :text="loader_text"  />
                        </transition>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pagination: null,
            pageNo: 1,
            loading: false,
            loader_text: 'Loading . . .',
            pujas: [],
            mobile: this.isMobile()
        };
    },
    created() {
        this.index();
    },
    watch: {
        pageNo: {
            handler(value) {
                this.index();
            },
            immediate: true
        }
    },
    methods: {
        index() {
            this.loading = true;
            axios.get('/api/user/e-pujas/get?page='+this.pageNo).then(res => {
                this.pujas = Object.values(res.data.epujas.data);
                this.pagination = {
                    page: res.data.epujas.current_page,
                    pageSize: res.data.epujas.per_page,
                    pageCount: res.data.epujas.last_page,
                    total: res.data.epujas.total
                };
                this.loading = false;
                this.emitter.emit('scroll_section')
            });
        }
    },
};
</script>
<style scoped>
.scale-move,.scale-enter-active, .scale-leave-active {
    transition: all 0.4s ease;
    transform-origin: center;
}
.scale-enter-from, .scale-leave-to {
    transform: scale(0);
    filter: blur(10px);
    opacity: 0;
}
.slide-down-enter-active, .slide-down-leave-active {
    transition: all 0.2s linear;
    transform-origin: center top;
}
.slide-down-enter-from, .slide-down-leave-to {
    transform: scale(0);
    filter: blur(10px);
}
</style>