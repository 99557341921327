<template>
    <div class="main">
        <section class="section" ref="section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <transition-group name="scale" mode="out-in" tag="div" class="row match-height">
                            <div class="col-md-6 col-12 col-lg-4" v-for="(p,index) in bookings" :key="index">
                                <div class="puja-card">
                                    <router-link :to="'/booking-detail/'+p.crypt" class="img-wrapper">
                                        <img :src="p.puja.banner" :alt="'Banner - '+ p.puja.title">
                                        <div class="category">{{ status(p.status) }}</div>
                                    </router-link>
                                    <div class="content">
                                        <router-link :to="'/booking-detail/'+p.crypt" class="puja-title mb-2">
                                            {{ p.puja.title }}
                                        </router-link>
                                        <p class="align-self-start font-weight-bold text-capitalize">Type: {{ p.type }}</p>
                                        <p class="align-self-start font-weight-bold">Booked For: {{ $filters.date(p.date) }}</p>
                                        <p class="align-self-start font-weight-bold">At Price: {{ p.currency_details.symbol+p.price }}</p>
                                    </div>
                                </div>
                            </div>
                            <nav key="Page navigation" aria-label="Page navigation" v-if="bookings.length">
                                <ul class="custom-pagination" v-if="pagination?.pageCount > 7">
                                    <li class="page-item prev-item" @click="pageNo = (pageNo > 1) ? pageNo - 1 : pagination?.pageCount"><i class="fas fa-sm fa-long-arrow-left"></i></li>
                                    <li class="page-item" :class="{ 'active' : pageNo==1 }" @click="pageNo=1"><a class="page-link" href="#">1</a></li>
                                <li class="divider" :class="{ 'active' : pageNo > 1 && pageNo < pagination?.pageCount }"></li>
                                    <li class="page-item" :class="{ 'active' : pageNo==pagination?.pageCount }" @click="pageNo = pagination?.pageCount"><a class="page-link" href="#">{{ pagination?.pageCount }}</a></li>
                                    <li class="page-item next-item" @click="pageNo = pageNo < pagination?.pageCount ? pageNo+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                </ul>
                                <ul class="custom-pagination" v-else>
                                    <li class="page-item prev-item" @click="pageNo = (pageNo > 1) ? pageNo - 1 : pagination?.pageCount"><i class="fas fa-long-arrow-left"></i></li>
                                    <li class="page-item" :class="{'active' : pageNo == i}" v-for="i in pagination?.pageCount" @click="pageNo=i" :key="i"><a class="page-link" href="#">{{ i }}</a></li>
                                    <li class="page-item next-item" @click="pageNo = pageNo < pagination?.pageCount ? pageNo+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                </ul>
                            </nav>
                        </transition-group>
                        <transition name="fade">
                            <loader v-if="loading" :text="loader_text"  />
                            <div class="row" v-else-if="!loading && !bookings.length">
                                <div class="col-12 col-lg-8 offset-lg-2">
                                    <div class="card">
                                        <div class="text-center row match-height">
                                            <div class="col-lg-6 spin-mandala ty">
                                                <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                                <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                            </div>
                                            <div class="col-lg-6">
                                                <img src="/app-assets/images/avatar.png" alt="Mandala">
                                            </div>
                                        </div>
                                        <p class="desc">
                                            Oops, You don't have any past bookings.<br><br>Book you Astro Puja with us now.
                                        </p>
                                        <div class="flex-cc">
                                            <router-link :to="'/astro-pujas'" class="btn orange-dark">Book Now</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
data() {
    return {
        pagination: null,
        pageNo: null,
        loading: false,
        loader_text: 'Loading . . .',
        bookings: [],
        token: this.isLogged(),
        mobile: this.isMobile(),
        config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
    };
},
created() {
    if(this.token) this.pageNo = 1;
    else this.loading = true
},
watch: {
    pageNo: {
        handler(value) {
            this.index();
        }
    }
},
methods: {
    index() {
        this.loading = true;
        axios.get('/api/user/my-bookings/get?page='+this.pageNo, this.config).then(res => {
            this.bookings = Object.values(res.data.bookings.data);
            this.pagination = {
                page: res.data.bookings.current_page,
                pageSize: res.data.bookings.per_page,
                pageCount: res.data.bookings.last_page,
                total: res.data.bookings.total
            };
            this.loading = false;
            this.emitter.emit('scroll_section')
        });
    },
    status(value) {
        if(value == 0) return 'Pending' ;
        else if(value == 1) return 'Cancelled - By User' ;
        else if(value == 2) return 'Cancelled - By Admin' ;
        else if(value == 3) return 'Approved' ;
        else if(value == 4) return 'Completed' ;
    }
},
};
</script>
<style scoped>
.scale-move,.scale-enter-active, .scale-leave-active {
transition: all 0.4s ease;
transform-origin: center;
}
.scale-enter-from, .scale-leave-to {
transform: scale(0);
filter: blur(10px);
opacity: 0;
}
.slide-down-enter-active, .slide-down-leave-active {
transition: all 0.2s linear;
transform-origin: center top;
}
.slide-down-enter-from, .slide-down-leave-to {
transform: scale(0);
filter: blur(10px);
}
</style>