<template>
    <div class="main">
        <section class="section orange pt-4" ref="section">
            <!-- <div v-if="!loading" class="animated-background"></div> -->
            <div class="container">
                <div class="row">
                    <transition name="grow" mode="out-in">
                        <div class="col-lg-8 offset-lg-2" v-if="loading">
                            <loader :text="'Loading. . .'" />
                        </div>
                        <div v-else-if="!loading && err" class="col-12 col-lg-8 offset-lg-2">
                            <div class="card">
                                <div class="text-center row match-height">
                                    <div class="col-lg-6 spin-mandala ty">
                                        <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                        <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                    </div>
                                    <div class="col-lg-6">
                                        <img src="/app-assets/images/avatar.png" alt="Mandala">
                                    </div>
                                </div>
                                <p class="desc">
                                    Oops, {{ err }}.<br><br>Please try again later.
                                </p>
                                <div class="flex-cc">
                                    <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-10 offset-lg-1" v-else-if="!loading && !err && result">
                            <button type="button" class="btn orange-dark large text-uppercase mx-auto mb-5">Numerology Result</button>
                            <transition-group tag="div" class="row match-height" name="flip">
                                <div class="col-md-6" v-for="(r,i) in result" :key="i">
                                    <div class="card outline hover-design">
                                        <div class="eclipse"><span></span><span></span><span></span><span></span><span></span><span></span></div>
                                        <div class="d-flex flex-column gap-2">
                                            <h4 class="title text-capitalize">{{ r.category }}</h4>
                                            <div class="flex-sc gap-3">
                                                <p class="description">
                                                    <b>{{ r.number }}</b>
                                                    <span class="dot"></span>
                                                    <span>{{ r.title }}</span>
                                                    <span v-if="r.master" class="dot"></span>
                                                    <span v-if="r.master">Master Number</span>
                                                </p>
                                            </div>
                                            <h6 class="mb-1 mt-3">Meaning</h6>
                                            <p class="description">{{ r.meaning }}</p>
                                            <h6 class="mb-1 mt-3">Description</h6>
                                            <p class="description">{{ r.description }}</p>
                                        </div>
                                    </div>
                                </div>
                            </transition-group>
                        </div>
                    </transition>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            id: '',
            result: [],
            data: [],
            loading: false,
            err: false,
       };
    },
    created() {
        if(this.token) {
            this.id = this.$route.params.id
            this.index()
        } else this.loading = true
    },
    computed: {
        array_fields() {
            return Object.keys(this.result).filter(key => typeof this.result[key] === 'object' && this.result[key] !== null && !Array.isArray(this.result[key]));
        },
    },
    methods: {
        index() {
            this.$Progress.start()
            this.loading = true
            axios.post('/api/user/get-numerology-result', {query: this.id}, this.config).then((res) => {
                this.loading = false
                if(res.data.status == 'success') {
                    this.data = res.data.data
                    this.result = res.data.result
                    this.$Progress.finish()
                } else {
                    this.err = res.data.message
                    this.$Progress.fail()
                }
                this.$nextTick(() => {
                    this.emitter.emit('scroll_section')
                    // this.emitter.emit('animate_background')
                })
            }).catch((err) => {
                this.$Progress.fail()
                toast.fire({'icon': 'error', 'title': 'Server Error Occured!'})
                this.loading = false
            })
        },
    }
}
</script>
<style scoped>
.flip-enter-active, .flip-leave-active {
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.flip-enter-from {
    transform: translateY(100%);
    opacity: 0;
    filter: blur(2px);
    
}
.flip-leave-to {
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(2px);
}
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s ease-in;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
    filter: blur(2px);
}
.grow-enter-active, .grow-leave-active {
    transition: all 0.5s ease-out;
}
.grow-enter-from {
    opacity: 0;
    filter: blur(15px);
    transform: scale(0);
    transform-origin: center;
}
.grow-leave-to {
    opacity: 0;
    filter: blur(15px);
    transform: scale(0);
    transform-origin: center;
}
</style>