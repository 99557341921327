<template>
    <section class="section orange">
        <div class="container">
            <div class="card profile-card">
                <div class="content">
                    <div class="photo">
                        <img src="/app-assets/images/h-logo.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>