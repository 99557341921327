<template>
    <div class="main">
        <transition name="flip">
            <section class="section yellow py-5" v-if="!token">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="card yellow mb-0">
                                <div class="row">
                                    <div class="col-lg-4 d-none d-lg-block">
                                        <img src="/app-assets/images/logo.png" :alt="'Logo '+$appname">
                                    </div>
                                    <div class="col-lg-8 flex-cc flex-column">
                                        <h6 class="mb-3">Please Login to save or view your previous kundli results!</h6>
                                        <button type="button" class="btn orange-dark" @click="emitter.emit('showlogin')">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                <path d="M12.5048 12.4102C11.4048 12.4102 10.4631 12.0185 9.67976 11.2352C8.89643 10.4518 8.50476 9.51016 8.50476 8.41016C8.50476 7.31016 8.89643 6.36849 9.67976 5.58516C10.4631 4.80182 11.4048 4.41016 12.5048 4.41016C13.6048 4.41016 14.5464 4.80182 15.3298 5.58516C16.1131 6.36849 16.5048 7.31016 16.5048 8.41016C16.5048 9.51016 16.1131 10.4518 15.3298 11.2352C14.5464 12.0185 13.6048 12.4102 12.5048 12.4102ZM4.50476 20.4102V17.6102C4.50476 17.0435 4.65059 16.5227 4.94226 16.0477C5.23393 15.5727 5.62143 15.2102 6.10476 14.9602C7.13809 14.4435 8.18809 14.056 9.25476 13.7977C10.3214 13.5393 11.4048 13.4102 12.5048 13.4102C13.6048 13.4102 14.6881 13.5393 15.7548 13.7977C16.8214 14.056 17.8714 14.4435 18.9048 14.9602C19.3881 15.2102 19.7756 15.5727 20.0673 16.0477C20.3589 16.5227 20.5048 17.0435 20.5048 17.6102V20.4102H4.50476ZM6.50476 18.4102H18.5048V17.6102C18.5048 17.4268 18.4589 17.2602 18.3673 17.1102C18.2756 16.9602 18.1548 16.8435 18.0048 16.7602C17.1048 16.3102 16.1964 15.9727 15.2798 15.7477C14.3631 15.5227 13.4381 15.4102 12.5048 15.4102C11.5714 15.4102 10.6464 15.5227 9.72976 15.7477C8.81309 15.9727 7.90476 16.3102 7.00476 16.7602C6.85476 16.8435 6.73393 16.9602 6.64226 17.1102C6.55059 17.2602 6.50476 17.4268 6.50476 17.6102V18.4102ZM12.5048 10.4102C13.0548 10.4102 13.5256 10.2143 13.9173 9.82266C14.3089 9.43099 14.5048 8.96016 14.5048 8.41016C14.5048 7.86016 14.3089 7.38932 13.9173 6.99766C13.5256 6.60599 13.0548 6.41016 12.5048 6.41016C11.9548 6.41016 11.4839 6.60599 11.0923 6.99766C10.7006 7.38932 10.5048 7.86016 10.5048 8.41016C10.5048 8.96016 10.7006 9.43099 11.0923 9.82266C11.4839 10.2143 11.9548 10.4102 12.5048 10.4102Z" fill="#fff"/>
                                            </svg> Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </transition>
        <section class="section orange pt-lg-5 pb-lg-0">
            <div class="container">
                <form class="row match-height" @submit.prevent="flow_check()">
                    <div class="col-12">
                        <div class="card">
                            <h4 class="text-center mb-4">New Kundli</h4>
                            <div class="row">
                                <div class="col-12 col-lg-4 form-group orange">
                                    <label>Full Name</label>
                                    <input type="text" class="form-control" :class="{'is-invalid': errors.name}" v-model="form.name">
                                    <span class="invalid-feedback" v-if="errors.name"><strong>{{ errors.name[0] }}</strong></span>
                                </div>
                                <div class="col-12 col-lg-3 form-group orange">
                                    <label>Date of Birth</label>
                                    <VueDatePicker hide-offset-dates :min-date="min_date" :max-date="max_date" :year-range="[1920, curr_year]" prevent-min-max-navigation :clearable="false" v-model="form.dob" :class="{'form-control is-invalid': errors.dob}" format="dd MMM, yyyy" :month-change-on-scroll="false" :enable-time-picker="false"></VueDatePicker>
                                    <span class="invalid-feedback" v-if="errors.dob"><strong>{{ errors.dob[0] }}</strong></span>
                                </div>
                                <div class="col-12 col-lg-3 form-group orange">
                                    <label>Time of Birth</label>
                                    <VueDatePicker :clearable="false" v-model="form.tob" :class="{'form-control is-invalid': errors.tob}"  model-type="HH:mm" format="h:mm a" time-picker></VueDatePicker>
                                    <span class="invalid-feedback" v-if="errors.tob"><strong>{{ errors.tob[0] }}</strong></span>
                                </div>
                                <div class="col-12 col-lg-2 form-group orange">
                                    <label class="mb-2"><h6>Language</h6></label>
                                    <v-select class="w-100" :options="api_langs" :clearable="false" v-model="form.lang" :reduce="i => i.value"></v-select>
                                </div>
                                <div class="col-12 col-lg-5 form-group orange">
                                    <label>Search Place of Birth</label>
                                    <GMapAutocomplete :placeholder="'Search Location'" @place_changed="getAddressData" :types="['geocode']" :fields="autocompleteFields" class="form-control" ></GMapAutocomplete>
                                </div>
                                <div class="col-12 col-lg-3 form-group orange">
                                    <label>Response Type</label>
                                    <v-select class="w-100" :options="response_types" :clearable="false" v-model="response" :reduce="i => i.value"></v-select>
                                </div>
                                <div class="col-12 col-lg-4 form-group orange" v-if="response=='pdf'">
                                    <label>Match Type</label>
                                    <v-select class="w-100" :options="types" :clearable="false" v-model="form.type" :reduce="i => i.value"></v-select>
                                </div>
                                <div class="col-12 col-lg-3" :class="{'pt-4': response!='pdf'}">
                                    <button type="submit" :disabled="disBtn == 1" class="btn dark block text-nowrap mt-1">
                                        <i v-if="disBtn == 1" class="fas fa-spinner fa-spin"></i>
                                        <transition name="flip" mode="out-in">
                                            <span v-if="disBtn == 1">Loading . . .</span>
                                            <span v-else>Generate Kundli</span>
                                        </transition>
                                    </button>
                                    <!-- <button type="submit" @click="type == 'pdf'" :disabled="disBtn == 2" class="btn dark block text-nowrap mt-3">
                                        <i v-if="disBtn == 2" class="fas fa-spinner fa-spin"></i>
                                        <transition name="flip" mode="out-in">
                                            <span v-if="disBtn == 2">Loading . . .</span>
                                            <span v-else>Generate Kundli PDF</span>
                                        </transition>
                                    </button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        <transition name="flip">
            <section class="section orange py-lg-5" ref="pageNav" v-if="kundlis.length">
                <div class="container">
                    <h4 class="section-title text-center">Kundli Results</h4>
                    <div class="row">
                        <div class="col-lg-6" v-for="(m,index) in kundlis">
                            <div class="card result-box">
                                <div class="flex-bc">
                                    <i class="date">Date: {{ $filters.timestamp(m.created_at)  }}</i>
                                    <div class="text-uppercase" :class="m.type == 'Free' ? 'text-success' : 'text-danger'">{{ m.type }}</div>
                                </div>
                                <div class="flex-bc">
                                    <i class="date">Language: {{ api_langs.find(i => i.value == m.data.lang).label  }}</i>
                                    <div v-if="m.response_type == 'pdf'" class="text-uppercase text-dark"><b>PDF Kundli</b></div>
                                </div>
                                <div class="detail-wrapper">
                                    <div class="d-none d-md-flex flex-column justify-content-evenly align-items-center gap-2">
                                        <img src="/app-assets/images/h-logo.png" alt="Logo Icon">
                                    </div>
                                    <div class="d-flex flex-column justify-content-between" :class="{'gap-2' : mobile}">
                                        <span>Name: {{ m.data.name }}</span>
                                        <span>DOB: {{ m.data.dob }}</span>
                                        <span>TOB: {{ m.data.tob }}</span>
                                        <span>City: {{ m.data.city }}</span>
                                    </div>
                                </div>
                                <div class="flex-bc">
                                    <router-link :to="'/transaction/'+m.transaction_crypt" class="btn orange-light small">
                                        <svg style="stroke-width: 2px;" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                            <circle cx="11.6398" cy="11.5" r="6" stroke="#fff"/>
                                            <path d="M11.6398 8.5C11.2458 8.5 10.8557 8.5776 10.4917 8.72836C10.1277 8.87913 9.79703 9.1001 9.51845 9.37868C9.23987 9.65726 9.0189 9.98797 8.86813 10.352C8.71737 10.7159 8.63977 11.106 8.63977 11.5" stroke="#fff" stroke-linecap="round"/>
                                            <path d="M20.6398 20.5L17.6398 17.5" stroke="#fff" stroke-linecap="round"/>
                                        </svg>
                                        View Receipt
                                    </router-link>
                                    <router-link v-if="m.response_type != 'pdf'" :to="'/kundli/'+m.crypt_id+'/result'" class="btn orange-light small">
                                        <svg style="stroke-width: 2px;" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                            <path d="M15.8118 5.5C17.2162 5.5 17.9185 5.5 18.4229 5.83706C18.6413 5.98298 18.8288 6.17048 18.9747 6.38886C19.3118 6.89331 19.3118 7.59554 19.3118 9V18.5C19.3118 20.3856 19.3118 21.3284 18.726 21.9142C18.1402 22.5 17.1974 22.5 15.3118 22.5H9.31177C7.42615 22.5 6.48334 22.5 5.89755 21.9142C5.31177 21.3284 5.31177 20.3856 5.31177 18.5V9C5.31177 7.59554 5.31177 6.89331 5.64883 6.38886C5.79475 6.17048 5.98225 5.98298 6.20063 5.83706C6.70507 5.5 7.40731 5.5 8.81177 5.5" stroke="#fff"/>
                                            <path d="M9.31177 5.5C9.31177 4.39543 10.2072 3.5 11.3118 3.5H13.3118C14.4163 3.5 15.3118 4.39543 15.3118 5.5C15.3118 6.60457 14.4163 7.5 13.3118 7.5H11.3118C10.2072 7.5 9.31177 6.60457 9.31177 5.5Z" stroke="#fff"/>
                                            <path d="M9.31177 12.5L15.3118 12.5" stroke="#fff" stroke-linecap="round"/>
                                            <path d="M9.31177 16.5L13.3118 16.5" stroke="#fff" stroke-linecap="round"/>
                                        </svg>
                                        View Result
                                    </router-link>
                                    <a v-else target="_blank" :href="m.result" class="btn orange-light small">
                                        <svg style="stroke-width: 2px;" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                            <path d="M15.8118 5.5C17.2162 5.5 17.9185 5.5 18.4229 5.83706C18.6413 5.98298 18.8288 6.17048 18.9747 6.38886C19.3118 6.89331 19.3118 7.59554 19.3118 9V18.5C19.3118 20.3856 19.3118 21.3284 18.726 21.9142C18.1402 22.5 17.1974 22.5 15.3118 22.5H9.31177C7.42615 22.5 6.48334 22.5 5.89755 21.9142C5.31177 21.3284 5.31177 20.3856 5.31177 18.5V9C5.31177 7.59554 5.31177 6.89331 5.64883 6.38886C5.79475 6.17048 5.98225 5.98298 6.20063 5.83706C6.70507 5.5 7.40731 5.5 8.81177 5.5" stroke="#fff"/>
                                            <path d="M9.31177 5.5C9.31177 4.39543 10.2072 3.5 11.3118 3.5H13.3118C14.4163 3.5 15.3118 4.39543 15.3118 5.5C15.3118 6.60457 14.4163 7.5 13.3118 7.5H11.3118C10.2072 7.5 9.31177 6.60457 9.31177 5.5Z" stroke="#fff"/>
                                            <path d="M9.31177 12.5L15.3118 12.5" stroke="#fff" stroke-linecap="round"/>
                                            <path d="M9.31177 16.5L13.3118 16.5" stroke="#fff" stroke-linecap="round"/>
                                        </svg>
                                        View PDF
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <nav key="Page navigation" aria-label="Page navigation" v-if="kundlis.length">
                                <ul class="custom-pagination" v-if="pagination?.pageCount > 7">
                                    <li class="page-item prev-item" @click="pageNo = (pageNo > 1) ? pageNo - 1 : pagination?.pageCount"><i class="fas fa-sm fa-long-arrow-left"></i></li>
                                    <li class="page-item" :class="{ 'active' : pageNo==1 }" @click="pageNo=1"><a class="page-link" href="#">1</a></li>
                                    <li class="divider" :class="{ 'active' : pageNo > 1 && pageNo < pagination?.pageCount }"></li>
                                    <li class="page-item" :class="{ 'active' : pageNo==pagination?.pageCount }" @click="pageNo = pagination?.pageCount"><a class="page-link" href="#">{{ pagination?.pageCount }}</a></li>
                                    <li class="page-item next-item" @click="pageNo = pageNo < pagination?.pageCount ? pageNo+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                </ul>
                                <ul class="custom-pagination" v-else>
                                    <li class="page-item prev-item" @click="pageNo = (pageNo > 1) ? pageNo - 1 : pagination?.pageCount"><i class="fas fa-long-arrow-left"></i></li>
                                    <li class="page-item" :class="{'active' : pageNo == i}" v-for="i in pagination?.pageCount" @click="pageNo=i" :key="i"><a class="page-link" href="#">{{ i }}</a></li>
                                    <li class="page-item next-item" @click="pageNo = pageNo < pagination?.pageCount ? pageNo+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
        </transition>
    </div>
</template>
<script>
import { Autoplay, Pagination, Keyboard, Navigation } from 'swiper';
export default {
    props: ['user','pricings','api_langs'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            errors: [],
            curr_year: moment().format('YYYY'),
            response: 'json',
            kundlis: [],
            cities_array: [],
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            modules: [Autoplay, Pagination, Keyboard, Navigation ],
            disBtn: false,
            min_date: moment('1920', 'YYYY').startOf('year').format('YYYY-MM-DD'),
            max_date: moment().format('YYYY-MM-DD'),
            pagination: null,
            pageNo: 1,
            city_search: false,
            types: [{
                value: 'north',
                label: 'North Match / Ashtakoot',
            },{
                value: 'south',
                label: 'South Match / Dashakoot',
            }],
            autocompleteFields : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity'],
            response_types: [{label: 'Web Response', value:'json'},{label: 'Download PDF', value:'pdf'}],
            form: new Form({
                lang: 'en',
                name: '', 
                dob: '', 
                tob: '', 
                // pob: '', 
                city: '', 
                // search: '', 
                lat: '', 
                lon: '', 
                tz: '', 
                type: 'north',
            }),
       };
    },
    created()  {
        if(this.token) {
            this.index()
            this.form.lang = this.user.language
            if(this.user.first_name) this.form.name = this.user.first_name
            if(this.user.last_name) this.form.name += (' '+this.user.last_name)
            if(this.user.dob) this.form.dob = this.user.dob
            if(this.user.tob) this.form.tob = this.user.tob
        }
        this.emitter.on("loggedIn", async token => {
            this.token = token;
            this.config = { headers: { Authorization: `Bearer ${token}` } }
        });
    },
    mounted()  {
        this.emitter.emit('scroll_section')
        // this.debouncedSearch = this.$debounce(this.search, 500);
        // this.emitter.emit('animate_background')
    },
    computed: {
        cities() {
            return this.cities_array.map((e,v) => {
                return {label: e.full_name, value: v}
            }) 
        },
        price() {
            if(this.user.currency.code == 'INR')
                return this.pricings.kundli_price_INR
            else
                return this.pricings.kundli_price_USD
        },
        pdf_price() {
            if(this.user.currency.code == 'INR')
                return this.pricings.kundli_pdf_price_INR
            else
                return this.pricings.kundli_pdf_price_USD
        },
    },
    watch: {
        pageNo (n,o) { 
            this.index() 
        },
    },
    methods: {
        index() {
            axios.get('/api/user/get-all-kundlis?page='+this.pageNo, this.config).then((res) => {
                this.kundlis = Object.values(res.data.kundlis.data)
                this.pagination = {
                    "page": res.data.kundlis.current_page,
                    "pageSize": res.data.kundlis.per_page,
                    "pageCount": res.data.kundlis.last_page,
                    "total": res.data.kundlis.total
                }
                this.$nextTick(() => {
                    // this.emitter.emit('animate_background')
                })
                this.$nextTick(() => {
                    this.emitter.emit('scroll_section')
                    // this.$refs.pageNav.scrollIntoView({behavior: 'smooth'})
                })
            }).catch((err) => {
                Swal.fire({icon: 'error', text: 'Server Error Occured!'})
            })
        },
        search() {
            this.city_search = true
            axios.post('/api/user/get-cities', {city : this.form.search }).then((res) => {
                this.city_search = false
                this.cities_array = res.data.response || []
            })  
        },
        putCity(e) {
            var city = this.cities_array.find( i => i.full_name == this.form.city) ;
            this.form.lat = city.coordinates[0]
            this.form.lon = city.coordinates[1]
            this.form.tz = city.tz
        },
        getAddressData(place) {
            this.form.lat = place.geometry.location.lat()
            this.form.lon = place.geometry.location.lng()
            this.form.tz = this.m2Tz(place.utc_offset_minutes)
            this.form.city = place.formatted_address
        },
        flow_check() {
            if(!this.token) {
                this.emitter.emit('showlogin')
                return ;
            }
            if(!this.form.lat || !this.form.lon) {
                toast.fire({'icon': 'info', 'title': 'Please Select Location from Dropdown!'})
                return ;
            }
            if(this.response == 'pdf') this.submit_pdf()  
            else this.submit()
        },
        async submit() {
            var flag = false;
            if (this.user.kundlis_count >= this.pricings.free_kundli_count && this.price != 0) {
                const res = await Swal.fire({
                    icon: 'info',
                    title: 'You have reached the limit.',
                    text: 'This kundli result will cost you ' + this.user.currency.symbol + this.price,
                    confirmButtonText: 'Proceed',
                });
                if (res.isConfirmed) {
                    if (this.user.wallet_amount - this.price < 0) {
                        const res2 = await Swal.fire({
                            icon: 'error',
                            title: 'Low Wallet Balance.',
                            text: 'Please recharge your wallet to continue!',
                            confirmButtonText: 'Recharge',
                        });
                        if (!res2.isConfirmed) return;
                        else this.$router.push('/astro-wallet')
                    } else flag = true;
                } else return;
            } else flag = true;
            if (flag) {
                this.$Progress.start();
                this.disBtn = 1;

                try {
                    const res = await axios.post('/api/user/kundli-submit', this.form, this.config);
                    this.disBtn = false;

                    if (res.data.status == 'success') {
                        this.$Progress.finish();
                        this.emitter.emit('get_user');
                        this.$router.push('/kundli/' + res.data.result_id + '/result');
                    } else {
                        console.log(res);
                        this.$Progress.fail();
                        toast.fire({ icon: 'error', title: 'Server Error Occurred!' });
                    }
                } catch (e) {
                    if (e.response && e.response.status === 422) this.errors = e.response.data.errors;
                    this.disBtn = false;
                    this.$Progress.fail();
                    toast.fire({ icon: 'error', title: e.message });
                }
            }
        },
        async submit_pdf() {
            if (this.pdf_price != 0) {
                const res = await Swal.fire({
                    icon: 'info',
                    title: 'Kundli PDF is a downloadable version.',
                    text: 'This kundli pdf will cost you ' + this.user.currency.symbol + this.pdf_price,
                    confirmButtonText: 'Proceed',
                });
                if (res.isConfirmed) {
                    if (this.user.wallet_amount - this.pdf_price < 0) {
                        const res2 = await Swal.fire({
                            icon: 'error',
                            title: 'Low Wallet Balance.',
                            text: 'Please recharge your wallet to continue!',
                            confirmButtonText: 'Recharge',
                        });
                        if (!res2.isConfirmed) return;
                        else this.$router.push('/astro-wallet')
                    }
                } else return;
            }

            this.$Progress.start();
            this.disBtn = 1;
            try {
                const res = await axios.post('/api/user/kundli-pdf-submit', this.form, this.config);
                this.disBtn = false;

                if (res.data.status == 'success') {
                    this.$Progress.finish();
                    this.index();
                    window.open(res.data.pdf_url, '_blank').focus();
                    // toast.fire({ icon: 'success', title: 'PDF Generated!' });
                } else {
                    this.$Progress.fail();
                    toast.fire({ icon: 'error', title: 'Server Error Occurred!' });
                }
            } catch(e) {
                if (e.response && e.response.status === 422) this.errors = e.response.data.errors;
                this.disBtn = false;
                this.$Progress.fail();
                toast.fire({ icon: 'error', title: e.message });
            }
        }
    }
}
</script>
<style scoped>
.flip-enter-active, .flip-leave-active {
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.flip-enter-from {
    transform: translateY(100%);
    opacity: 0;
    filter: blur(2px);
    
}
.flip-leave-to {
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(2px);
}
</style>