<template>
    <section class="section yellow">
        <div class="container">
            <!-- moving-border -->
            <h4 class="section-title flex-bc moving-border">
                <span>Latest from Blog</span>
                <router-link to="/blogs" class="text-dark">View All</router-link>
            </h4>
            <swiper
                :class="'blogs-swiper'"
                :keyboard="blogs.length > 1 ? { enabled: true } : false"
                :pagination="{ dynamicBullets: true, clickable: true }"
                :autoplay="false"
                :breakpoints="blog_breakpoints"
                :navigation="true"
                :loop="false"
                :speed="500"
                :grid="{ rows: 1 }"
                :modules="modules"
            >
                <!-- :autoHeight="true" -->
            <swiper-slide v-for="(i,index) in blogs" :key="index">
                <blog-card :blog="i" />
            </swiper-slide>
            </swiper>
        </div>
    </section>
</template>
<script setup>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Autoplay, Pagination, Keyboard, Navigation } from 'swiper';
</script>
<script>
export default {
    data() {
        return {
            blogs: [],
            modules: [Autoplay, Pagination, Keyboard, Navigation ],
            blog_breakpoints: {
                '0': {
                    slidesPerView: 1,
                },
                '768': {
                    slidesPerView: 2,
                },
                '1024': {
                    slidesPerView: 3,
                },
            },
        };
    },
    created() {
        this.index()
    },
    methods: {
        index() {
            axios.get('/api/user/blogs-section/get').then(res => {
                this.blogs = res.data.blogs
            })
        },
    }
}
</script>