<template>
    <section class="section orange" ref="section">
        <!-- <div class="animated-background"></div> -->
        <div class="container">
            <div class="row">
                <!-- <h4 class="text-center mb-5">Kindly fill up the below form to complete your application process.</h4> -->
                <transition name="fade" mode="out-in">
                    <div v-if="step==1" class="col-12 col-lg-10 offset-lg-1">
                        <div class="card">
                            <form class="row" @submit.prevent="sendOtp">
                                <div class="col-12">
                                    <h4>Personal Details <hr></h4>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4 form-group orange required">
                                    <label>Full Name</label>
                                    <input :disabled="disBtn == 1 || otpSent" required type="text" v-model="personal.name" class="form-control" :class="{'is-invalid' : errors.name}">
                                    <span v-if="errors.name" class="invalid-feedback"><strong>{{ errors.name[0] }}</strong></span>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4 form-group orange required">
                                    <label>Email</label>
                                    <input :disabled="disBtn == 1 || otpSent" required type="email" v-model="personal.email" class="form-control" :class="{'is-invalid' : errors.email}">
                                    <span v-if="errors.email" class="invalid-feedback"><strong>{{ errors.email[0] }}</strong></span>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4 form-group orange required">
                                    <label>Mobile Number</label>
                                    <input :disabled="disBtn == 1 || otpSent" required type="number" maxlength="10" minlength="10" @keypress="validate" v-model="personal.mobile" class="form-control" :class="{'is-invalid' : errors.mobile}">
                                    <span v-if="errors.mobile" class="invalid-feedback"><strong>{{ errors.mobile[0] }}</strong></span>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4 form-group orange required">
                                    <label>Date of Birth</label>
                                    <input :disabled="disBtn == 1 || otpSent" :max="maxDate" required type="date" v-model="personal.dob" class="form-control" :class="{'is-invalid' : errors.dob}">
                                    <span v-if="errors.dob" class="invalid-feedback"><strong>{{ errors.dob[0] }}</strong></span>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4 form-group orange required">
                                    <label>Marital Status</label>
                                    <select :disabled="disBtn == 1 || otpSent" required v-model="personal.marital_status" class="form-control" :class="{'is-invalid' : errors.marital_status}">
                                        <option value="" hidden></option>
                                        <option value="Single">Single</option>
                                        <option value="Married">Married</option>
                                    </select>
                                    <span v-if="errors.marital_status" class="invalid-feedback"><strong>{{ errors.marital_status[0] }}</strong></span>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4 form-group orange required">
                                    <label>Pincode</label>
                                    <input :disabled="disBtn == 1 || otpSent" required type="tel" maxlength="6" minlength="6" @keypress="validate" v-model="personal.pincode" class="form-control" :class="{'is-invalid' : errors.pincode}">
                                    <span v-if="errors.pincode" class="invalid-feedback"><strong>{{ errors.pincode[0] }}</strong></span>
                                </div>
                                <div class="col-12 form-group">
                                    <div class="dropbox" :class="{ 'disabled' : disBtn == 1 || otpSent, 'is-invalid' : errors.all_skills}">
                                        <h6>Select All Skills <small><i>(Maximum 5)</i></small><hr></h6>
                                        <div class="content">
                                            <div class="checks" v-for="(i,index) in skills" :key="index">
                                                <input type="checkbox" :id="'check_'+i" :value="i" :disabled="!personal.all_skills.includes(i) && personal.all_skills.length > 4" multiple v-model="personal.all_skills">
                                                <label :for="'check_'+i">{{ i }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <span v-if="errors.all_skills" class="invalid-feedback"><strong>{{ errors.all_skills[0] }}</strong></span>
                                </div>
                                <div class="col-12 form-group">
                                    <div class="dropbox" :class="{ 'disabled' : disBtn == 1 || otpSent, 'is-invalid' : errors.languages}">
                                        <h6>Select Languages You Know<hr></h6>
                                        <div class="content">
                                            <div class="checks" v-for="(i,index) in langs" :key="index">
                                                <input type="checkbox" :id="'check_'+i" :value="i" multiple v-model="personal.languages">
                                                <label :for="'check_'+i">{{ i }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <span v-if="errors.languages" class="invalid-feedback"><strong>{{ errors.languages[0] }}</strong></span>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4 form-group orange required">
                                    <label>Primary Skill</label>
                                    <select :disabled="disBtn == 1 || otpSent" required v-model="personal.primary_skill" class="form-control" :class="{'is-invalid' : errors.primary_skill}">
                                        <option value="" hidden>Selct One</option>
                                        <option v-for="(s,index) in personal.all_skills" :key="index" :value="s">{{ s }}</option>
                                    </select>
                                    <span v-if="errors.primary_skill" class="invalid-feedback"><strong>{{ errors.primary_skill[0] }}</strong></span>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4 form-group orange required">
                                    <label>Experience <small><i>(in yrs)</i></small></label>
                                    <input :disabled="disBtn == 1 || otpSent" required type="tel" maxlength="2" minlength="1" @keypress="validate" v-model="personal.exp_years" class="form-control" :class="{'is-invalid' : errors.exp_years}">
                                    <span v-if="errors.exp_years" class="invalid-feedback"><strong>{{ errors.exp_years[0] }}</strong></span>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4 form-group orange required">
                                    <label>Daily availability <small><i>(in hrs)</i></small></label>
                                    <input :disabled="disBtn == 1 || otpSent" required type="number" max="24" min="1" @keypress="validate" v-model="personal.hours_daily" class="form-control" :class="{'is-invalid' : errors.hours_daily}">
                                    <span v-if="errors.hours_daily" class="invalid-feedback"><strong>{{ errors.hours_daily[0] }}</strong></span>
                                </div>
                                <div class="col-12 form-group orange required">
                                    <label>Where did you hear about us?</label>
                                    <input :disabled="disBtn == 1 || otpSent" required type="text" v-model="personal.about_us" class="form-control" maxlength="255" :class="{'is-invalid' : errors.about_us}">
                                    <span v-if="errors.about_us" class="invalid-feedback"><strong>{{ errors.about_us[0] }}</strong></span>
                                </div>
                                <div class="col-12 col-md-6 form-group orange flex-sc gap-4 align-items-end pb-3">
                                    <label>Gender: </label>
                                    <div class="form-check custom-radio">
                                        <input :disabled="disBtn == 1 || otpSent" type="radio" required name="gender" @click="personal.gender = 'male'" :checked="personal.gender == 'male' ? true : false" class="form-check-input" id="male">
                                        <label for="male" class="form-check-label">Male</label>
                                    </div>
                                    <div class="form-check custom-radio">
                                        <input :disabled="disBtn == 1 || otpSent" type="radio" name="gender" @click="personal.gender = 'female'" :checked="personal.gender == 'female' ? true : false" class="form-check-input" id="female">
                                        <label for="female" class="form-check-label">Female</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 form-group orange flex-sc gap-4 align-items-end pb-3">
                                    <label>Are you working on any other platform? </label>
                                    <div class="form-check custom-radio">
                                        <input :disabled="disBtn == 1 || otpSent" type="radio" required name="other_platforms" @click="personal.other_platform = 'yes'" :checked="personal.other_platform == 'yes' ? true : false" class="form-check-input" id="yes">
                                        <label for="yes" class="form-check-label">Yes</label>
                                    </div>
                                    <div class="form-check custom-radio">
                                        <input :disabled="disBtn == 1 || otpSent" type="radio" name="other_platforms" @click="personal.other_platform = 'no'" :checked="personal.other_platform == 'no' ? true : false" class="form-check-input" id="no">
                                        <label for="no" class="form-check-label">No</label>
                                    </div>
                                </div>
                                <div class="col-12 flex-cc">
                                    <div class="form-check custom-radio">
                                        <input type="checkbox" required :value="true" @click="tnc = !tnc" :checked="tnc" class="form-check-input" id="tnc">
                                        <label for="tnc" class="form-check-label">I agree to the <router-link class="text-orange" to="#">T&C</router-link> and <router-link class="text-orange" to="#">Privacy Policy</router-link>.</label>
                                    </div>
                                </div>
                                <transition name="collapse">
                                    <div v-if="!otpSent" class="col-md-4 offset-md-4 form-group">
                                        <button :disabled="!tnc || disBtn == 1" type="submit" class="btn block orange-dark text-uppercase mt-3"> <i v-if="disBtn == 1" class="fas fa-spinner fa-spin"></i> send otp</button>
                                    </div>
                                </transition>
                                <transition name="collapse">
                                    <form v-if="otpSent" class="row" @submit.prevent="verify_otp()">
                                        <div class="form-group orange col-md-4 offset-md-4 text-center">
                                            <label>Mobile OTP</label>
                                            <div class="flex-cc gap-3">
                                                <input type="password" maxlength="1" @keypress="validate" @keyup="onNumber" minlength="1" v-model="mobileOtp.otp_1" class="form-control otp-input">
                                                <input type="password" maxlength="1" @keypress="validate" @keyup="onNumber" @keyup.delete="$event.target.previousElementSibling.focus()" minlength="1" v-model="mobileOtp.otp_2" class="form-control otp-input">
                                                <input type="password" maxlength="1" @keypress="validate" @keyup="onNumber" @keyup.delete="$event.target.previousElementSibling.focus()" minlength="1" v-model="mobileOtp.otp_3" class="form-control otp-input">
                                                <input type="password" maxlength="1" @keypress="validate" ref="mo4" @keyup="$refs.eo1.focus()" @keyup.delete="$event.target.previousElementSibling.focus()" minlength="1" v-model="mobileOtp.otp_4" class="form-control otp-input">
                                            </div>
                                        </div>
                                        <div class="form-group orange col-md-4 offset-md-4 text-center">
                                            <label>Email OTP</label>
                                            <div class="flex-cc gap-3">
                                                <input type="password" maxlength="1" @keypress="validate" ref="eo1" @keyup="onNumber" @keyup.delete="$refs.mo4.focus()" minlength="1" v-model="emailOtp.otp_1" class="form-control otp-input">
                                                <input type="password" maxlength="1" @keypress="validate" @keyup="onNumber" @keyup.delete="$event.target.previousElementSibling.focus()" minlength="1" v-model="emailOtp.otp_2" class="form-control otp-input">
                                                <input type="password" maxlength="1" @keypress="validate" @keyup="onNumber" @keyup.delete="$event.target.previousElementSibling.focus()" minlength="1" v-model="emailOtp.otp_3" class="form-control otp-input">
                                                <input type="password" maxlength="1" @keypress="validate" @keyup.delete="$event.target.previousElementSibling.focus()" minlength="1" v-model="emailOtp.otp_4" class="form-control otp-input">
                                            </div>
                                        </div>
                                        <div class="col-md-4 offset-md-4">
                                            <button :disabled="disBtn == 2" type="submit" class="btn block orange-dark text-uppercase mt-3"> <i v-if="disBtn == 2" class="fas fa-spinner fa-spin"></i> verify and submit</button>
                                        </div>
                                    </form>
                                </transition>
                            </form>
                        </div>
                    </div>
                    <div v-else-if="step==2" class="col-12 col-lg-8 offset-lg-2">
                        <div class="card">
                            <div class="text-center row match-height">
                                <div class="col-lg-6 spin-mandala ty">
                                    <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                    <img src="/app-assets/images/TY.png" alt="TY" class="avatar">
                                </div>
                                <div class="col-lg-6">
                                    <img src="/app-assets/images/avatar.png" alt="Mandala">
                                </div>
                            </div>
                            <p class="desc">
                                Dear Scholar, <br><br>
                                Thank you for your interest and participation! We appreciate your enthusiasm. 
                                <!-- Your token number is {{ token }}. -->
                                You can now proceed to your skill test by clicking on the button below. However, if you prefer to take the test later, the link has been shared in the email sent to you.<br><br>Please be informed that the test link shared is valid for 15 days only. Ensure timely completion to progress smoothly.<br><br>Best of luck!
                            </p>
                            <div class="flex-cc">
                                <router-link :to="'/scholar-skill-test?token='+token" class="btn orange-dark">Take The Skill Test</router-link>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </section>
</template>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s ease-in;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
    filter: blur(2px);
}
.collapse-enter-active, .collapse-leave-active {
    transform-origin: top;
    transition: all 0.3s ease-in;
}

.collapse-enter-to, .collapse-leave-from {
    transform: scaleY(1);
    opacity: 1;
    filter: blur(0);
}

.collapse-enter-from, .collapse-leave-to {
    transform: scaleY(0);
    opacity: 0;
    filter: blur(5px);
}
i.fas {
    transition: all 0.2s ease-in ;
}
.flip {
    transform: rotate(180deg);
}
</style>
<script>
// import useValidate from '@vuelidate/core'
// import { required } from '@vuelidate/validators'
export default {
    props: ['skills', 'langs'],
    data() {
        return {
            // v$: useValidate(),
            personal: new Form({
                name: '',
                dob: '',
                email: '',
                mobile: '',
                marital_status: 'Single',
                pincode: '',
                gender: 'male',
                primary_skill: '',
                languages: [],
                all_skills: [],
                exp_years: '',
                hours_daily: '',
                about_us: '',
                other_platform: 'no',
            }),
            tnc: true,
            disBtn: false,
            otpSent: false, 
            maxDate: '',
            otpm: '',
            otpe: '',
            rgd: '',
            token: false,
            step: 1,
            mobile: this.isMobile(),
            mobileOtp: new Form({
                otp_1: '',
                otp_2: '',
                otp_3: '',
                otp_4: '',
            }),
            emailOtp: new Form({
                otp_1: '',
                otp_2: '',
                otp_3: '',
                otp_4: '',
            }),
            skills: ['Jyotish','Vedic Karamkand','Vedic','KP System','Lal Kitab','Vastu','Tarot Reading','Nadi','Numerology','Marriage Matching','Ashtakvarga','Palmistry','Ramal','Jaimini','Tajik','Western','Kerala','Swar Shastra','Reiki','Crystal Healing','Angel Reading','Pendulum Dowsing','Feng Shui','Prashna','Psychic Reading','Face Reading','Muhurta'],
            languages: ['Hindi','English','Bengali','Marathi','Telugu','Tamil','Gujarati','Kannada','Malayalam','Assamese','Odia','Punjabi','Urdu','Bhojpuri','Nepali','Maithili','Dogri','Kashmiri','Konkani','Sindhi','Haryanvi','Rajasthani','Manipuri','Sanskrit','Kumaoni','Tulu','Santali','Marwari','Spanish','French','Arabic','Chinese','Russian','Portuguese','Indonesian','Japanese'],
            errors: [],
        };
    },
    created() {
        this.maxDate = this.personal.dob = moment().subtract(15, 'years').format('YYYY-MM-DD');
    },
    // validations() {
    //     return {
    //         personal: {
    //             name: {required, email},
    //         }
    //     }
    // },
    mounted()  {
        // this.emitter.emit('animate_background')
    },
    methods: {
        onNumber(event) {
            if (/^\d$/.test(event.key)) {
                event.target.nextElementSibling.focus()
            } else {
                event.preventDefault();
            }
        },
        sendOtp() {
            this.$Progress.start()
            this.errors = []
            this.disBtn = 1 ;
            axios.post('/api/scholar/send-otp', this.personal).then((res) => {
                this.$Progress.finish()
                this.disBtn = false ;
                this.otpSent = true ;
                this.otpm = res.data.otpm ;
                this.otpe = res.data.otpe ;
            }).catch((e) => {
                if (e.response.status === 422) this.errors = e.response.data.errors;
                this.$Progress.fail()
                this.disBtn = false ;
            })
        },
        verify_otp() {
            this.$Progress.start()
            this.errors = []
            this.disBtn = 2 ;
            var data = {
                otpm: this.otpm,
                otpe: this.otpe,
                mobileOtp: this.mobileOtp,
                emailOtp: this.emailOtp,
            }
            axios.post('/api/scholar/verify-otp', data).then((res) => {
                toast.fire({'icon': res.data.status, 'title': res.data.message })
                
                if(res.data.status == 'success') {
                    this.$Progress.finish()
                    this.token = res.data.token ;
                    this.step = 2
                    this.emitter.emit('scroll_section')
                }

                this.disBtn = false ;
            }).catch((e) => {
                if (e.response.status === 422) this.errors = e.response.data.errors;
                this.$Progress.fail()
                this.disBtn = false ;
            })
        },
    }
}
</script>