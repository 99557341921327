<template>
    <section class="section orange">
        <div class="container">
            <!-- moving-border -->
            <h4 class="section-title flex-bc moving-border">
                <span>Talk with Astrologer</span>
                <router-link to="/astrologers?action=talk" class="text-dark">View All</router-link>
            </h4>
            <swiper
                :class="'talkbox-swiper'"
                :keyboard="astrologers.length > 1 ? { enabled: true } : false"
                :pagination="{ dynamicBullets: true, clickable: true }"
                :autoplay="false"
                :breakpoints="talk_breakpoints"
                :navigation="false"
                :loop="false"
                :speed="500"
                :autoHeight="false"
                :modules="modules"
            >
                <swiper-slide v-for="(p,index) in pandits" :key="index">
                    <talk-box :user="user" :pandit="p" />
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>
<script setup>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Autoplay, Pagination, Keyboard, Navigation } from 'swiper';
</script>
<script>
export default {
    props: ['user', 'pandits'],
    data() {
        return {
            astrologers: [],
            modules: [Autoplay, Pagination, Keyboard, Navigation ],
            talk_breakpoints: {
                '0': {
                    slidesPerView: 1,
                },
                '768': {
                    slidesPerView: 3,
                },
                '1024': {
                    slidesPerView: 4,
                },
                '1300': {
                    slidesPerView: 5,
                },
            },
        };
    },
}
</script>