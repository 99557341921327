<template>
    <div class="main">
        <transition name="flip">
            <section class="section orange py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-10 offset-lg-1">
                            <transition name="fade">
                                <div v-if="!err" class="ql-editor" v-html="text"></div>
                                <div class="card" v-else-if="err">
                                    <div class="text-center row match-height">
                                        <div class="col-lg-6 spin-mandala ty">
                                            <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                            <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                        </div>
                                        <div class="col-lg-6" v-if="!mobile">
                                            <img src="/app-assets/images/avatar.png" alt="Mandala">
                                        </div>
                                    </div>
                                    <p class="desc">Oops, {{ err }}</p>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </section>
        </transition>
    </div>
</template>
<script>
export default {
    data() {
        return {
            mobile: this.isMobile(),
            slug: null,
            text: null,
            err: false,
        };
    },
    created() {
        this.slug = this.$route.params.slug
        this.index()
    },
    watch: {
        '$route' : function(n, o) {
            this.slug = this.$route.params.slug
            this.index()
        },
    },
    methods: {
        index() {
            axios.get('/api/user/policy-page/get/'+this.slug).then((res) => {
                if(res.data.status == 'success') this.text = res.data.text
                else this.err = true
                this.emitter.emit('scroll_section')
            }).catch((err) => {
                this.err = true
            })
        },
    }
}
</script>