<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <span class="footer-head">{{ $appname }}</span>
                    <p>{{ $appname }} is the best astrology website for online Astrology predictions. Talk to Astrologer on call and get answers to all your worries by seeing the future life through Astrology Kundli Predictions from the best Astrologers from India. Get best future predictions related to Marriage, love life, Career or Health over call, chat, query or report.</p>
                </div>
                <div class="col-lg-4">
                    <span class="list-head">Contact Us</span>
                    <p class="mt-2">We are available 24x7 on chat support, click to start chat.</p>
                    <div class="flex-sc gap-1">
                        <a href="mailto:contact@astrobahrat.com" class="btn yellow">
                            <i class="far fa-envelope"></i> contact@astrobahrat.com
                        </a>
                    </div>
                </div>
                <div class="col-lg-4">
                    <span class="list-head">Corporate Info</span>
                    <ul>
                        <li v-if="policies?.[0]?.is_active"><router-link :to="'/policy-page/'+policies?.[0]?.label">Terms & Conditions</router-link></li>
                        <li v-if="policies?.[1]?.is_active"><router-link :to="'/policy-page/'+policies?.[1]?.label">Privacy Policy</router-link></li>
                        <li v-if="policies?.[2]?.is_active"><router-link :to="'/policy-page/'+policies?.[2]?.label">Refund Policy</router-link></li>
                        <li v-if="policies?.[3]?.is_active"><router-link :to="'/policy-page/'+policies?.[3]?.label">Shipping Policy</router-link></li>
                        <li v-if="policies?.[4]?.is_active"><router-link :to="'/policy-page/'+policies?.[4]?.label">Disclaimer</router-link></li>
                        <li v-if="policies?.[5]?.is_active"><router-link :to="'/policy-page/'+policies?.[5]?.label">Cancellation Policy</router-link></li>
                    </ul>                    
                </div>
                <div class="col-lg-4">
                    <span class="list-head">We Provide</span>
                    <ul class="features">
                        <li><img src="/app-assets/images/f1.png" alt="Feature 1"><span>Money back gurantee</span></li>
                        <li><img src="/app-assets/images/f2.png" alt="Feature 2"><span>Verified expert astrologers</span></li>
                        <li><img src="/app-assets/images/f3.png" alt="Feature 3"><span>100% Secure Payments</span></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="container">
                <div class="flex-bc flex-column flex-md-row gap-3 text-center text-md-left">
                    <p>Copyright &copy; 2023 | {{ $appname }} Services Private Limited | All Rights Reserved</p>
                    <ul class="social-links">
                        <li><a href="https://www.facebook.com/people/AstroForu/61564449123267/" target="_blank" class="btn yellow"><i class="fab me-0 fa-facebook-f"></i></a></li>
                        <li><a href="https://www.instagram.com/astroforu9/" target="_blank" class="btn yellow"><i class="fab me-0 fa-instagram"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UClBBV-6HOmR_YikJ94dVgQQ" target="_blank" class="btn yellow"><i class="fab me-0 fa-youtube"></i></a></li>
                        <li><a href="https://x.com/AstroForU9" target="_blank" class="btn yellow"><i class="fab me-0 fa-x-twitter"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    props: ['policies'],
}
</script>