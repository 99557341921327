<template>
	<div class="main">
		<section class="section" ref="section">
			<div class="container">
				<div class="row">
					<div v-if="error" class="col-12 col-lg-8 offset-lg-2">
						<div class="card">
							<div class="text-center row match-height">
								<div class="col-lg-6 spin-mandala ty">
									<img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
									<img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
								</div>
								<div class="col-lg-6">
									<img src="/app-assets/images/avatar.png" alt="Mandala">
								</div>
							</div>
							<p class="desc">
								<span v-if="error == 1">
									Oops, Live Stream Not Found.<br><br>Please try again later.
								</span>
								<span v-else-if="error == 2">
									Oops, Live Stream has ended.
								</span>
							</p>
							<div class="flex-cc">
								<router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
							</div>
						</div>
					</div>
					<div class="col-12" v-else>
						<div class="row">
							<div class="col-lg-4 offset-lg-1" v-show="enable_join && joined">
								<video ref="videoElement" :style="'object-fit: contain; width: 100%; aspect-ratio: ' + (mobile ? '2 / 3': '3 / 2') +'; transform: scaleX(-1);'" />
								<audio ref="audioElement" />
								<div></div>
							</div>
							<div class="col-lg-4 offset-lg-1" v-show="!joined && docdata">
								<img v-if="docdata" :src="docdata.image" alt="Live Stream Banner">
							</div>
							<div class="col-lg-7">
								<div class="chat-contianer" :class="mobile ? 'p-4' : ''">
                                    <transition-group name="fade" tag="div" class="row">
                                        <div class="chat-head mb-2" key="head">
                                            <div class="user-info">
                                                <div class="position-relative">
                                                    <img v-if="docdata?.image" :src="docdata?.image" :alt="docdata?.name">
                                                    <img v-else src="/app-assets/images/h-logo.png">
                                                    <span class="status online"></span>
                                                </div>
                                                <div class="wrapper">
                                                    <div class="price">Astrologer</div>
                                                    <div class="name">{{ docdata?.name }}</div>
                                                </div>
                                            </div>
                                            <div class="flex-bc">
												<!-- <div v-if="joined">Viewers: {{ participants }}</div> -->
												<div class="flex-sc gap-1 ms-auto">
													<button type="button" @click="join_call" v-if="!(!enable_join || joined)" class="btn yellow small">Join Call</button>
													<button type="button" @click="leave_call" v-else-if="enable_join && joined" class="btn yellow small">Leave</button>
												</div>
                                            </div>
                                        </div>
                                        <div class="col-12 msg-container py-lg-5" key="loading" v-if="c_loading">
                                            <div class="d-flex text-white">
                                                <i class="fas fa-spinner fa-spin fa-6x mx-auto mt-3 mb-4"></i>
                                            </div>
                                            <h4 class="text-center mb-3">Chat Loading. . .</h4>
                                        </div>
                                        <div class="col-12 msg-container py-lg-5" key="empty" v-if="!c_loading && messages.length == 0">
                                            <div class="d-flex text-white">
                                                <i class="fas fa-frown fa-6x mx-auto mt-3 mb-4"></i>
                                            </div>
                                            <h4 class="text-center mb-3">No Messages Yet. . .</h4>
                                        </div>
                                        <transition-group tag="div" key="messages" class="col-12 scroll_container msg-container" v-if="chat_reverse.length" name="fade">
                                            <div class="text-center" v-if="msg_loading">
                                                <i class="fas fa-spinner fa-spin fa-3x my-3 text-white"></i>
                                            </div>
                                            <div v-for="(m,ind) in chat_reverse" :key="ind">
                                                <div class="message-block" :class="{'chat-right' : m.senderId == user.id }">
													<div class="stream-chat-block">
														<span class="name" v-if="m.senderId != user.id">{{ m.senderName }}</span>
														<span v-if="m.messageType == 'string'" class="message">{{ m.message }}</span>
													</div>
                                                    <span class="time">{{ $filters.UnixTS(m.messageTimestamp) }}</span>
                                                </div>
                                            </div>
                                        </transition-group>
                                        <form @submit.prevent="send_message" v-if="joined" key="footer" id="sendmessage" class="chat-footer">
                                            <div class="input-group input-group-merge me-1 form-send-message">
                                                <!-- <span class="input-group-text" @click="speech"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mic cursor-pointer"><path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path><path d="M19 10v2a7 7 0 0 1-14 0v-2"></path><line x1="12" y1="19" x2="12" y2="23"></line><line x1="8" y1="23" x2="16" y2="23"></line></svg></span> -->
                                                <input type="text" class="form-control message" v-model="text" placeholder="Type your message or use speech to text">
												<span class="input-group-text pe-2">
													<button type="submit" class="btn small">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<path d="M9.50978 4.23062L18.0698 8.51062C21.9098 10.4306 21.9098 13.5706 18.0698 15.4906L9.50978 19.7706C3.74978 22.6506 1.39978 20.2906 4.27978 14.5406L5.14978 12.8106C5.36978 12.3706 5.36978 11.6406 5.14978 11.2006L4.27978 9.46062C1.39978 3.71062 3.75978 1.35062 9.50978 4.23062Z" stroke="#38210F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M5.43994 12H10.8399" stroke="#38210F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</button>
												</span>
                                            </div>
                                        </form>
                                    </transition-group>
                                </div>
							</div>
						</div>						
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<style scoped>
.scale-move,.scale-enter-active, .scale-leave-active {
	transition: all 0.4s ease;
}
.scale-enter-from, .scale-leave-to {
	transform: scale(0);
	filter: blur(10px);
	opacity: 0;
}
</style>
<script>
import { CallingState, StreamVideoClient } from "@stream-io/video-client";
import { collection, doc, getDocs, increment, limit, onSnapshot, orderBy, query, setDoc, startAfter, updateDoc, where } from "firebase/firestore";
import Hls from "hls.js";
export default {
	props: ['user'],
	data() {
		return {
			apiKey: this.$stream_api, 
			token: this.isLogged(),
			mobile: this.isMobile(),
			participants: 0,
			enable_join: false, 
			live: false,
			joined: false,
			error: false,
			call_id: '', 
			doc_id: '', 
			call: '',
			docdata: null,
			remoteParticipant: null,
			videoBindingsCache: new Map(),
			videoTrackingCache: new Map(),
			audioBindingsCache: new Map(),
			chatref: null,
            messages: [],
            chat_reverse: [],
            msg_loading: false,
            c_loading: false,
            last_limit: null,
            text: '',
		};
	},
	created() {
		this.call_id = this.$route.params.call_id
		this.index()
	},
	beforeDestroy() {
		this.interval()
	},
	methods: {
		async index() {
			var q = query(collection(db, 'live-streams'), where('call_id', '==', this.call_id))
			var snapshot = onSnapshot(q, (snap) => {
				if(!snap.docs.length) {
					this.error = 1
				}
				else {
					this.docdata = snap.docs[0].data() ;
					this.doc_id = snap.docs[0].id ;
					if(this.docdata.status == 3) {
						this.call.leave();
						this.error = 2
					}
					else this.start_stream()
				}
			})
		},
		start_stream() {
			const user = {
				id: this.$app_env+'Customer-'+(this.user.id.toString()),
				name: this.user.first_name,
			};
			const token = this.user.stream_token
			const apiKey = this.apiKey

			const client = new StreamVideoClient({apiKey,token,user});
			this.call = client.call("livestream", this.call_id);

			window.addEventListener("beforeunload", () => {
				this.call.leave();
			});

			this.call.camera.disable();
			this.call.microphone.disable();
			this.call.get().catch((error) => console.error(error));

			this.call.state.backstage$.subscribe((backstage) => {
				this.live = backstage ? true : false;
				if (!backstage && this.call.state.callingState === CallingState.IDLE) {
					// this.join_call()
					this.enable_join = true
				}
			});

			this.remoteParticipant = this.call.state.remoteParticipants$.subscribe((participants) => {
				if (participants && participants.length > 0) {
					if (this.$refs.videoElement) {
						this.unbindVideoElement = this.call.bindVideoElement(this.$refs.videoElement,participants[0].sessionId || 'sessionId','videoTrack')
						const untrack = this.call.trackElementVisibility(this.$refs.videoElement,participants[0].sessionId || 'sessionId',"videoTrack");
						this.videoTrackingCache.set(`video-${participants[0].sessionId}`, untrack);
					}
					if (this.$refs.audioElement) {
						this.unbindAudioElement = this.call.bindAudioElement(this.$refs.audioElement,participants[0].sessionId || 'sessionId')
						this.audioBindingsCache.set(`audio-${participants[0].sessionId}`, this.unbindVideoElement);
					}
				}
			});

			this.call.state.participantCount$.subscribe((count) => {
				this.participants = count || 0
			});

			const hlsContainer = document.getElementById("hls-stream");
			this.call.state.egress$.subscribe((egress) => {
				if (egress?.hls?.playlist_url) {
					const videoEl = document.createElement("video") ;
					// videoEl.style.setProperty("object-fit", "contain");
					// videoEl.style.setProperty("width", "100%");
					// videoEl.style.setProperty("aspect-ratio", this.mobile ? '4 / 5' : "5 / 4");
					// videoEl.style.setProperty("transform", "scaleX(-1)");

					var hls = new Hls();
					hls.on(Hls.Events.MEDIA_ATTACHED, function () {
						console.log("video and hls.js are now bound together !");
					});
					hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
					});
					hls.loadSource(egress?.hls?.playlist_url);
					hls.attachMedia(videoEl);
					hlsContainer.innerHTML = "";
					hlsContainer.appendChild(videoEl);
				}
			});
		},
		join_call() {
			this.call.join();
			this.joined = true
			var docRef = doc(db, "live-streams", this.doc_id)
			updateDoc(docRef, {
                "total_participants" : increment(1),
            })
			this.chatref = collection(docRef, 'chats')
			this.openChat()
		},
		leave_call() {
			this.call.leave();
			this.$router.push('/')
		},
		async openChat() {
            this.c_loading = true;

            var colRef = query(this.chatref, orderBy("messageTimestamp","desc"), limit(100))
            this.interval = onSnapshot(colRef, (querySnapshot) => {
                this.messages = []
                querySnapshot.forEach((document) => {
                    var docdata = document.data();
                    this.messages.push(docdata);
                });
                this.last_limit = querySnapshot.docs[querySnapshot.docs.length-1];
                this.c_loading = false
                this.chat_reverse = this.messages.reverse();
                $('.msg-container').animate({ scrollTop: $('.msg-container')[0].scrollHeight }, 400);
                $('.msg-container')[0].addEventListener('scroll', this.chat_scroll);
            });

            this.c_loading = false
		},
		async chat_scroll(e) {
            if($(e.target).scrollTop()==0) {
                this.msg_loading = true;
                var q = query(this.chatref, orderBy("messageTimestamp","desc"), startAfter(this.last_limit), limit(100))
                var docSnap =  await getDocs(q);
                if(docSnap.docs.length > 0) {
                    docSnap.forEach((data) => {
                        this.chat_reverse.unshift(data.data());
                    })
                    this.last_limit = docSnap.docs[docSnap.docs.length-1];
                }
                this.msg_loading = false;
                // $('.msg-container').animate({ scrollTop: $('.msg-container')[0].scrollHeight }, 400);
            }
        },
		send_message(){
            if(this.text == '') return

            var str = Date.now().toString() ;
            setDoc(doc(this.chatref, str), {
                "senderId" : this.user.id,
                "senderName" : this.user.first_name ?? 'Anonymous',
                "message" : this.text,	
                "messageType" : 'string',
                "messageTimestamp" : str,
            })
            this.text = ''
            $('.msg-container .message-block:last-child')[0].scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
        },
		speech() {
            var SpeechRecognition = SpeechRecognition || webkitSpeechRecognition;
            if (SpeechRecognition !== undefined && SpeechRecognition !== null) {
                var recognition = new SpeechRecognition();
                recognition.lang = 'en-IN' ;

                recognition.onspeechstart = function () {
                    this.listening = true;
                };
                if (this.listening === false) {
                    recognition.start();
                }
                recognition.onerror = function (event) {
                    this.listening = false;
                };
                recognition.onresult = function (event) {
                    this.text = event.results[0][0].transcript ;
                };
                recognition.onspeechend = function (event) {
                    this.listening = false;
                    recognition.stop();
                };
            }
        },
	}
}
</script>
