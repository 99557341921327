<template>
    <div class="main">
        <section class="section orange pt-lg-5">
            <div class="container">
                <div class="my-lg-5">
                    <div class="row">
                        <div class="col-12 col-lg-10 offset-lg-1">
                            <ul class="result-nav">
                                <li :class="{'active' : tab == 1}" @click="tab=1">Basic</li>
                                <li :class="{'active' : tab == 2}" @click="tab=2">Kundli</li>
                                <li :class="{'active' : tab == 3}" @click="tab=3">KP</li>
                                <li :class="{'active' : tab == 4}" @click="tab=4">Ashtakvarga</li>
                                <li :class="{'active' : tab == 5}" @click="tab=5">charts</li>
                                <li :class="{'active' : tab == 6}" @click="tab=6">dasha</li>
                                <li :class="{'active' : tab == 7}" @click="tab=7">free report</li>
                            </ul>
                        </div>
                        <div class="col-12">
                            <transition name="fade" mode="out-in">
                                <div class="row" v-if="tab == 1" key="basic">
                                    <div class="col-lg-6">
                                        <!-- {{ custom_result.details[0] }} -->
                                        <h5 class="text-uppercase mb-3">Birth Details</h5>
                                        <table class="table kundli-table">
                                            <tbody>
                                                <tr>
                                                    <td class="bg">Name</td>
                                                    <td>{{ data.name }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Date of Birth</td>
                                                    <td>{{ data.dob }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Day of Birth</td>
                                                    <td>{{ custom_result.details[0].panchang.day_of_birth }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Time of Birth</td>
                                                    <td>{{ data.tob }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Timezone</td>
                                                    <td>{{ 'GMT +'+data.tz }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">City</td>
                                                    <td>{{ data.city }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Latitude</td>
                                                    <td>{{ data.lat }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Longitude</td>
                                                    <td>{{ data.lon }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Current Dasha</td>
                                                    <td>{{ custom_result.details[0].current_dasa }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Dasha at Birth</td>
                                                    <td>{{ custom_result.details[0].birth_dasa }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Hora Lord</td>
                                                    <td>{{ custom_result.details[0].panchang.hora_lord }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Day Lord</td>
                                                    <td>{{ custom_result.details[0].panchang.day_lord }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Yoga</td>
                                                    <td>{{ custom_result.details[0].panchang.yoga }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Karana</td>
                                                    <td>{{ custom_result.details[0].panchang.karana }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Nakshatra</td>
                                                    <td>{{ custom_result.details[0].nakshatra }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Tithi</td>
                                                    <td>{{ custom_result.details[0].panchang.tithi }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Sunrise</td>
                                                    <td>{{ custom_result.details[0].panchang.sunrise_at_birth }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Sunset</td>
                                                    <td>{{ custom_result.details[0].panchang.sunset_at_birth }}</td>
                                                </tr>
                                                <!-- <tr>
                                                    <td class="bg">Moon phase</td>
                                                    <td>{{ custom_result.details[0].panchang.dob }}</td>
                                                </tr> -->
                                                <tr>
                                                    <td class="bg">Ayanamsa</td>
                                                    <td>{{ decimalToDMS(custom_result.details[0].panchang.ayanamsa) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h5 class="text-uppercase mt-4 mt-md-0 mb-3">Other Details</h5>
                                        <table class="table kundli-table">
                                            <tbody>
                                                <tr>
                                                    <td class="bg">Lucky Number</td>
                                                    <td class="text-capitalize">{{ custom_result.details[0].lucky_num.join(', ') }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Baby Name Start</td>
                                                    <td class="text-capitalize">{{ custom_result.details[0].lucky_name_start.join(', ') }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Lucky Color</td>
                                                    <td class="text-capitalize">{{ custom_result.details[0].lucky_colors.join(', ') }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Lucky Gem</td>
                                                    <td class="text-capitalize">{{ custom_result.details[0].lucky_gem.join(', ') }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-lg-6">
                                        <h5 class="text-uppercase mt-4 mt-md-0 mb-3">Avakhada Details</h5>
                                        <table class="table kundli-table">
                                            <tbody>
                                                <tr>
                                                    <td class="bg">Varna</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['varna'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Vasya</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['vasya'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Yoni</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['yoni'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Gan</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['gana'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Nadi</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['nadi'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Sign</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['rasi'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Sign Lord</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['rasi_lord'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Nakshatra-Charan</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['nakshatra'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Yog</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['yoga'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Karan</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['karana'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Tithi</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['tithi'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Yunja</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['nakshatra_pada'] <= 6 ? 'Aadi' : (custom_result.details[4]['nakshatra_pada'] > 6 && custom_result.details[4]['nakshatra_pada'] <= 18 ? 'Madhya' : 'Anta') }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Tatva</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['tatva'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Name Alphabet</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['name_start'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Paya</td>
                                                    <td class="text-capitalize">{{ custom_result.details[4]['paya'] }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="card outline">
                                            <div class="">
                                                <h4 class="text-orange text-center">Unlock Your Full Kundli Report!</h4>
                                                <h6 class="text-dark text-center my-4">Get the Extended Version now available<br>in PDF format.</h6>
                                                <button type="button" class="btn orange-dark mx-auto mb-4" @click="showModal='pdf_kundli'">Create PDF Now</button>
                                                <ul>
                                                    <li style="list-style-type: disc;">Click Here to download the report</li>
                                                    <li style="list-style-type: disc;">Separate Kundli images included</li>
                                                    <li style="list-style-type: disc;">Easy, fast, and secure download</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-else-if="tab == 2" key="kundli">
                                    <div class="col-12 col-lg-10 offset-lg-1">
                                        <ul class="result-nav small" :class="{'justify-content-center': mobile}">
                                            <li :class="{'active' : type == 'north'}" @click="type='north'">North Indian</li>
                                            <li :class="{'active' : type == 'south'}" @click="type='south'">South Indian</li>
                                        </ul>
                                    </div>
                                    <div class="col-12 col-lg-8 offset-lg-2">
                                        <transition name="fade" mode="out-in">
                                            <div class="row" v-if="type=='north'">
                                                <div class="col-lg-6 col-10 offset-1 offset-md-0">
                                                    <p class="mt-4 mt-md-0 mb-3 text-capitalize text-brown">{{ labels[0] }}</p>
                                                    <div class="svg-container">
                                                        <img :src="custom_result.charts[0]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-10 offset-1 offset-md-0">
                                                    <p class="mt-4 mt-md-0 mb-3 text-capitalize text-brown">{{ labels[5] }}</p>
                                                    <div class="svg-container">
                                                    <img :src="custom_result.charts[10]" :alt="'Chart Image'"></div>
                                                </div>
                                            </div>
                                            <div class="row" v-else>
                                                <div class="col-lg-6 col-10 offset-1 offset-md-0">
                                                    <p class="mt-4 mt-md-0 mb-3 text-capitalize text-brown">{{ labels[0] }}</p>
                                                    <div class="svg-container">
                                                    <img :src="custom_result.charts[1]" :alt="'Chart Image'"></div>
                                                </div>
                                                <div class="col-lg-6 col-10 offset-1 offset-md-0">
                                                    <p class="mt-4 mt-md-0 mb-3 text-capitalize text-brown">{{ labels[5] }}</p>
                                                    <div class="svg-container">
                                                    <img :src="custom_result.charts[11]" :alt="'Chart Image'"></div>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-12">
                                        <h5 class="text-uppercase mb-3 mt-4 mt-md-0">planets</h5>
                                        <div class="table-responsive">
                                            <table class="table kundli-table" :class="{'text-nowrap': mobile}">
                                                <thead>
                                                    <tr>
                                                        <th>Planet</th>
                                                        <th>Sign</th>
                                                        <th>Sign Lord</th>
                                                        <th>Nakshatra</th>
                                                        <th>Nakshatra Lord</th>
                                                        <th>Degree</th>
                                                        <th>Retro (R)</th>
                                                        <th>combust</th>
                                                        <th>avastha</th>
                                                        <th>house</th>
                                                        <th>status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="i in 9" :key="i">
                                                        <td>{{ custom_result.details[0][i].full_name }}</td>
                                                        <td>{{ custom_result.details[0][i].zodiac }}</td>
                                                        <td>{{ custom_result.details[0][i].zodiac_lord }}</td>
                                                        <td>{{ custom_result.details[0][i].nakshatra }}</td>
                                                        <td>{{ custom_result.details[0][i].nakshatra_lord }}</td>
                                                        <td>{{ custom_result.details[0][i].global_degree }}</td>
                                                        <td>{{ custom_result.details[0][i].retro ? 'Yes' : 'No' }}</td>
                                                        <td>{{ custom_result.details[0][i].is_combust ? 'Yes' : 'No' }}</td>
                                                        <td>{{ custom_result.details[0][i].basic_avastha }}</td>
                                                        <td>{{ custom_result.details[0][i].house }}</td>
                                                        <td>{{ custom_result.details[0][i].lord_status }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-else-if="tab == 3" key="kp">
                                    <div class="col-12 col-lg-10 offset-lg-1">
                                        <ul class="result-nav small" :class="{'justify-content-center': mobile}">
                                            <li :class="{'active' : type == 'north'}" @click="type='north'">North Indian</li>
                                            <li :class="{'active' : type == 'south'}" @click="type='south'">South Indian</li>
                                        </ul>
                                    </div>
                                    <div class="col-12 col-lg-8 offset-lg-2">
                                        <transition name="fade" mode="out-in">
                                            <div class="row" v-if="type=='north'">
                                                <div class="col-lg-6 col-10 offset-1 offset-md-0 offset-lg-3">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[16] + ' - North' }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[32]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-else>
                                                <div class="col-lg-6 col-10 offset-1 offset-md-0 offset-lg-3">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[16] + ' - South' }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[33]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-12 col-lg-10 offset-lg-1">
                                        <h5 class="text-uppercase mb-3">Cusps</h5>
                                        <div class="table-responsive mb-5">
                                            <table class="table kundli-table text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Cusp</th>
                                                        <th>Degree</th>
                                                        <th>Sign</th>
                                                        <th>Sign Lord</th>
                                                        <th>Star Lord</th>
                                                        <th>Sub Lord</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(c,i) in custom_result.details[6]" :key="i">
                                                        <td>{{ c.house }}</td>
                                                        <td>{{ c.global_start_degree }}</td>
                                                        <td>{{ c.start_rasi }}</td>
                                                        <td>{{ c.start_nakshatra_lord.substring(0,2) }}</td>
                                                        <td>{{ c.cusp_sub_lord.substring(0,2) }}</td>
                                                        <td>{{ c.cusp_sub_sub_lord.substring(0,2) }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- <h5 class="text-uppercase mb-3">KP Planets</h5>
                                        <div class="table-responsive mb-5">
                                            <table class="table kundli-table text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Planet</th>
                                                        <th>Sign</th>
                                                        <th>Sign Lord</th>
                                                        <th>Nakshatra</th>
                                                        <th>Naksh Lord</th>
                                                        <th>Degree</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>#001</td>
                                                        <td>Retail</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> -->
                                    </div>
                                    <!-- <div class="col-12">
                                        <h5 class="text-uppercase mb-3">planets</h5>
                                        <div class="table-responsive mb-5">
                                            <table class="table kundli-table text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Planet</th>
                                                        <th>Sign</th>
                                                        <th>Sign Lord</th>
                                                        <th>Nakshatra</th>
                                                        <th>Naksh Lord</th>
                                                        <th>Degree</th>
                                                        <th>Retro (R)</th>
                                                        <th>combust</th>
                                                        <th>avastha</th>
                                                        <th>house</th>
                                                        <th>status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>#001</td>
                                                        <td>Retail</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#001</td>
                                                        <td>Retail</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#001</td>
                                                        <td>Retail</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                        <td>Jun 01,2023</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="row" v-else-if="tab == 4" key="ashtakvarga">
                                    <div class="col-lg-10 offset-lg-1">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0" v-for="c,i in custom_result.details[1].ashtakvarga_order" :key="i">
                                                <p class="text-uppercase mb-3">{{ c }}</p>
                                                <div class="kundli-north">
                                                    <div class="sub"></div>
                                                    <div class="line1"></div>
                                                    <div class="line2"></div>
                                                    <p :class="'p'+(1+k)" v-for="p,k in custom_result.details[1].ashtakvarga_points[i]">
                                                        {{ p }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-else-if="tab == 5" key="charts">
                                    <div class="col-lg-10 offset-lg-1">
                                        <ul class="result-nav small" :class="{'justify-content-center': mobile}">
                                            <li :class="{'active' : type == 'north'}" @click="type='north'">North Indian</li>
                                            <li :class="{'active' : type == 'south'}" @click="type='south'">South Indian</li>
                                        </ul>
                                    </div>
                                    <transition name="fade" mode="out-in">
                                        <div class="col-12" v-if="type=='north'">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[16] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[32]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[17] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[34]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[18] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[36]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[0] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[0]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[1] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[2]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[2] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[4]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[3] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[6]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[4] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[8]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[5] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[10]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[6] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[12]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[7] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[14]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[8] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[16]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[9] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[18]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[10] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[20]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[11] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[22]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[12] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[24]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[13] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[26]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[14] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[28]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[15] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[30]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12" v-else>
                                            <div class="row">
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[16] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[33]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[17] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[35]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[18] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[37]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[0] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[1]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[1] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[3]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[2] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[5]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[3] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[7]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[4] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[9]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[5] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[11]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[6] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[13]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[7] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[15]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[8] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[17]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[9] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[19]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[10] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[21]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[11] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[23]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[12] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[25]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[13] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[27]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[14] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[29]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 col-10 offset-1 offset-md-0">
                                                    <p class="mb-3 text-capitalize text-brown">{{ labels[15] }}</p>
                                                    <div class="svg-container mb-5">
                                                        <img :src="custom_result.charts[31]" :alt="'Chart Image'">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                                <div class="row" v-else-if="tab == 6" key="dasha">
                                    <div class="col-12 col-lg-10 offset-lg-1">
                                        <!-- <div class="flex-sc gap-4 mb-5">
                                            <button type="button" :class="d_type == 1 ? 'orange-light' :''" @click="d_type = 1" class="btn rounded-pill">Vimshottari Antardasha</button>
                                            <button type="button" :class="d_type == 2 ? 'orange-light' :''" @click="d_type = 2" class="btn rounded-pill">Yogini</button>
                                        </div>
                                         -->
                                        <ul class="result-nav small">
                                            <li :class="{'active' : d_type==1}" @click="d_type=1;">Vimshottari Antardasha</li>
                                            <li :class="{'active' : d_type==2}" @click="d_type=2;">Yogini</li>
                                        </ul>
                                    </div>
                                    <transition name="fade" mode="out-in">
                                        <div class="col-12" v-if="d_type == 1">
                                            <div class="row">
                                                <div class="col-lg-4" v-for="d,i in custom_result.details[13].antardashas">
                                                    <table class="table kundli-table">
                                                        <thead>
                                                            <tr>
                                                                <th colspan="2" class="text-center"><b>{{ d[0].split('/')[0] }}</b></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="2" class="py-4"><b>
                                                                    {{ custom_result.details[13].antardasha_order[i][0] }} - 
                                                                    {{ custom_result.details[13].antardasha_order[i][d.length-1] }}
                                                                </b></td>
                                                            </tr>
                                                            <tr v-for="c,j in d">
                                                                <td>{{ c.split('/')[1] }}</td>
                                                                <td>{{ custom_result.details[13].antardasha_order[i][j] }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-10 offset-lg-1" v-else>
                                            <div class="row">
                                                <div class="col-12 col-lg-10 offset-lg-1">
                                                    <ul class="result-nav small" :class="{'justify-content-center': mobile}">
                                                        <li :class="{'active' : type == 'north'}" @click="type='north'">North Indian</li>
                                                        <li :class="{'active' : type == 'south'}" @click="type='south'">South Indian</li>
                                                    </ul>
                                                </div>
                                                <div class="col-12 col-lg-8 offset-lg-2">
                                                    <transition name="fade" mode="out-in">
                                                        <div class="row" v-if="type=='north'">
                                                            <div class="col-lg-6 col-10 offset-1 offset-md-0 offset-lg-3">
                                                                <p class="text-uppercase mb-3 mt-4">Rashi Chart - North</p>
                                                                <div class="svg-container"></div>
                                                                    <img :src="custom_result.charts[0]" :alt="'Chart Image'">
                                                            </div>
                                                        </div>
                                                        <div class="row" v-else>
                                                            <div class="col-lg-6 col-10 offset-1 offset-md-0 offset-lg-3">
                                                                <p class="text-uppercase mb-3 mt-4">Rashi Chart - South</p>
                                                                <div class="svg-container"></div>
                                                                    <img :src="custom_result.charts[1]" :alt="'Chart Image'">
                                                            </div>
                                                        </div>
                                                    </transition>
                                                </div>
                                            </div>
                                            <div class="breadcrumb-wrapper">
                                                <ul class="flex-sc gap-2 mb-4">
                                                    <li class="">{{ custom_result.details[11].dasha_list[0] }}</li>
                                                    <li v-if="subRecords.length != 0">
                                                        <i class="fas fa-chevron-right"></i>
                                                        {{ subRecords.main_dasha }}
                                                    </li>
                                                </ul>
                                            </div>
                                            <transition name="slide" mode="out-in">
                                                <div class="table-responsive" v-if="tab == 6 && d_type == 2 && subRecords.length == 0">
                                                    <table class="table kundli-table text-nowrap">
                                                        <thead>
                                                            <tr>
                                                                <th>Planet</th>
                                                                <th>Start Date</th>
                                                                <th>End Date</th>
                                                                <th>Dasha Lord</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <!-- .slice(0,10) -->
                                                            <tr v-for="c,i in custom_result.details[11].dasha_list" :key="i">
                                                                <td>{{ c }}</td>
                                                                <td v-if="i != 0">{{ custom_result.details[11].dasha_end_dates[i-1] }}</td>
                                                                <td v-else>Birth</td>
                                                                <td>{{ custom_result.details[11].dasha_end_dates[i] }}</td>
                                                                <td @click="setSubRecord('yogini', i)">
                                                                    <div class="flex-bc">
                                                                        <span>{{ custom_result.details[11].dasha_lord_list[i] }}</span>
                                                                        <i class="fas cursor-pointer fa-chevron-right fa-lg"></i>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div v-else-if="tab == 6 && d_type == 2 && subRecords.length != 0">
                                                    <div class="table-responsive">
                                                        <table class="table kundli-table text-nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <th>Planet</th>
                                                                    <th>Start Date</th>
                                                                    <th>End Date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="c,i in subRecords.sub_dasha_list" :key="i">
                                                                    <td>{{ c }}</td>
                                                                    <td v-if="subRecordIndex == 0 && i == 0">Birth</td>
                                                                    <td v-else-if="subRecordIndex != 0 && i == 0">{{ custom_result.details[11].dasha_end_dates[subRecordIndex-1] }}</td>
                                                                    <td v-else>{{ subRecords.sub_dasha_end_dates[i-1] }}</td>
                                                                    <td>{{ subRecords.sub_dasha_end_dates[i] }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="my-4">
                                                        <button type="button" @click="subRecords = []" class="btn orange-dark mx-auto">Go Back</button>
                                                    </div>
                                                </div>
                                            </transition>
                                        </div>
                                    </transition>
                                </div>
                                <div class="row" v-else-if="tab == 7" key="free_report">
                                    <div class="col-12 col-lg-10 offset-lg-1">
                                        <ul class="nav custom-tabs">
                                            <li :class="{'active' : d_type==1}" @click="d_type=1;s_type=1">General</li>
                                            <li :class="{'active' : d_type==2}" @click="d_type=2;s_type=1">Remedies</li>
                                            <li :class="{'active' : d_type==3}" @click="d_type=3;s_type=1">Dosha</li>
                                            <li class="border"></li>
                                        </ul>
                                        <transition name="fade" mode="out-in">
                                            <div v-if="d_type == 1">
                                                <ul class="result-nav small">
                                                    <li :class="{'active' : s_type == 1}" @click="s_type=1">General</li>
                                                    <li :class="{'active' : s_type == 2}" @click="s_type=2">Planetary</li>
                                                    <li :class="{'active' : s_type == 3}" @click="s_type=3">Vimshottari Dasha</li>
                                                </ul>
                                                <transition name="fade" mode="out-in">
                                                    <div v-if="s_type == 1">
                                                        <div class="card outline shadow-none" v-for="c,i in custom_result.details[14]">
                                                            <h6 class="mb-4 text-capitalize">{{ i }}</h6>
                                                            <p class="description">{{ c.description }}</p>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="s_type == 2">
                                                        <div class="card outline" v-for="c,i in custom_result.planets">
                                                            <!-- <p class="description mb-4 flex-bc" :class="{'flex-column align-items-start': mobile}">
                                                                <b>{{ c.lord_of_zodiac }}</b>
                                                                <span>{{ c.verbal_location }}</span>
                                                            </p> -->
                                                            <h6 class="mb-4">{{ c.planet_considered }}</h6>
                                                            <p class="description">{{ c.personalised_prediction }}</p>
                                                            <p class="description">{{ c.general_prediction }}</p>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="s_type == 3">
                                                        <div class="card outline" v-for="c,i in custom_result.details[10].dashas">
                                                            <p class="description mb-4 flex-bc" :class="{'flex-column align-items-start': mobile}">
                                                                <b>{{ c.dasha }} {{ custom_result.details[10].translated_mahadasha }}</b>
                                                                <span v-if="!mobile">({{ c.dasha_start_year + ' - ' + c.dasha_end_year }})</span>
                                                            </p>
                                                            <p v-if="mobile">Start Date - <b>{{ c.dasha_start_year }}</b></p>
                                                            <p v-if="mobile">End Date - <b>{{ c.dasha_end_year }}</b></p>
                                                            <p :class="{'mt-3': mobile}">{{ c.prediction }}</p>
                                                            <!-- <br><p>{{ c.planet_in_zodiac }}</p> -->
                                                        </div>
                                                    </div>
                                                </transition>
                                            </div>
                                            <div v-else-if="d_type == 2">
                                                <ul class="result-nav small">
                                                    <li :class="{'active' : s_type == 1}" @click="s_type=1">Rudraksha</li>
                                                    <li :class="{'active' : s_type == 2}" @click="s_type=2">Gemstones</li>
                                                </ul>
                                                <transition name="fade" mode="out-in">
                                                    <div v-if="s_type == 1">
                                                        <div class="card outline">
                                                            <div class="d-flex flex-column gap-2">
                                                                <h5 class="title text-capitalize">Rudraksh Suggestion<hr></h5>
                                                                <h6 class="mb-1 mt-3">Short Read</h6>
                                                                <p class="description">{{ custom_result.details[2].bot_response }}</p>
                                                                <h6 class="mb-1 mt-3">Recommendation</h6>
                                                                <p class="description">{{ custom_result.details[2].mukhi_description }}</p>
                                                                <div class="mb-1 mt-3 flex-sc gap-3">
                                                                    <h6>Mukhi for Wealth: </h6>
                                                                    <p class="description">{{ custom_result.details[2].mukhi_for_money }}</p>
                                                                </div>
                                                                <div class="mb-1 mt-3 flex-sc gap-3">
                                                                    <h6>Mukhi for Health: </h6>
                                                                    <p class="description">{{ custom_result.details[2].mukhi_for_disease_cure }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="s_type == 2">
                                                        <div class="card outline">
                                                            <div class="d-flex flex-column">
                                                                <h5 class="title text-capitalize">Gemstone Suggestion<hr></h5>
                                                                <div class="row">
                                                                    <div class="col-lg-9 order-2 order-lg-1">
                                                                        <h6 class="mt-3 mb-1">Recommended to Wear: </h6>
                                                                        <p class="description">{{ custom_result.details[3].name }}</p>
                                                                        <h6 class="mt-3 mb-1">Other Name: </h6>
                                                                        <p class="description">{{ custom_result.details[3].other_name }}</p>
                                                                    </div>
                                                                    <div class="col-lg-3 order-1 order-lg-2 text-center" v-if="custom_result.details[3].key">
                                                                        <img :src="'/app-assets/images/stones/'+custom_result.details[3].key+'.png'" :class="{'w-50' : mobile}" :alt="custom_result.details[3].key+' - Gemstone Image'">
                                                                    </div>
                                                                </div>
                                                                <h6 class="mb-1 mt-3">Description: </h6>
                                                                <p class="description">{{ custom_result.details[3].description }}</p>
                                                                <h6 class="mt-3 mb-1">Benifits: </h6>
                                                                <p class="description">{{ custom_result.details[3].good_results.join(', ') }}</p>
                                                                <h6 class="mt-3 mb-1">Diseases Cure: </h6>
                                                                <p class="description">{{ custom_result.details[3].diseases_cure.join(', ') }}</p>
                                                                <h6 class="mt-3 mb-1">Best Time to Wear: </h6>
                                                                <p class="description">{{ custom_result.details[3].time_to_wear_short }}</p>
                                                                <h6 class="mb-1 mt-3">Method to Wear: </h6>
                                                                <p class="description">{{ custom_result.details[3].methods }}.</p>
                                                                <h6 class="mt-3 mb-1">Mantra: </h6>
                                                                <p class="description">{{ custom_result.details[3].mantra }}.</p>
                                                                <h6 class="mb-3 mt-3">Flaw Results: </h6>
                                                                <div class="row">
                                                                    <div class="col-lg-8">
                                                                        <table class="table kundli-table text-capitalize">
                                                                            <tbody>
                                                                                <tr v-for="r in custom_result.details[3].flaw_results">
                                                                                    <td>{{ r.flaw_type }}</td>
                                                                                    <td>{{ r.flaw_effects }}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>
                                            </div>
                                            <div v-else-if="d_type == 3">
                                                <ul class="result-nav small">
                                                    <li :class="{'active' : s_type == 1}" @click="s_type=1">Manglik</li>
                                                    <li :class="{'active' : s_type == 2}" @click="s_type=2">Kalsarpa</li>
                                                    <li :class="{'active' : s_type == 3}" @click="s_type=3">Sadesati</li>
                                                    <li :class="{'active' : s_type == 4}" @click="s_type=4">Pitra Dosh</li>
                                                </ul>
                                                <transition name="fade" mode="out-in">
                                                    <div v-if="s_type == 1">
                                                        <h4 class="mb-4">Manglik Analysis</h4>
                                                        <div class="card outline">
                                                            <div class="flex-sc flex-column flex-md-row gap-4">
                                                                <div>
                                                                    <div :class="custom_result.details[7].manglik_by_mars ? 'bg-danger px-3' : 'bg-success px-4'" class="rounded-circle py-4 text-white">{{ custom_result.details[7].manglik_by_mars ? 'Yes' : 'No' }}</div>
                                                                </div>
                                                                <div>
                                                                    <p class="description mb-3">{{ custom_result.details[7].factors.join(' ') }}</p>
                                                                    <p>[This is a computer generated result. Please consult an Astrologer to confirm & understand this in detail.]</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="s_type == 2">
                                                        <h4 class="mb-4">Kalsarpa Analysis</h4>
                                                        <div class="card outline">
                                                            <div class="flex-sc flex-column flex-md-row gap-4">
                                                                <div>
                                                                    <div :class="custom_result.details[8].is_dosha_present ? 'bg-danger px-3' : 'bg-success px-4'" class="rounded-circle py-4 text-white">{{ custom_result.details[8].is_dosha_present ? 'Yes' : 'No' }}</div>
                                                                </div>
                                                                <div>
                                                                    <p class="description mb-3">{{ custom_result.details[8].bot_response }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="s_type == 3">
                                                        <h4 class="mb-4">Sadesati Analysis</h4>
                                                        <div class="card outline">
                                                            <div class="flex-sc flex-column flex-md-row gap-4">
                                                                <div>
                                                                    <div :class="current_sade_sati ? 'bg-danger px-3' : 'bg-success px-4'" class="rounded-circle py-4 text-white">{{ current_sade_sati ? 'Yes' : 'No' }}</div>
                                                                </div>
                                                                <div>
                                                                    <p class="description"><b>Current Sade Sati Status</b></p>
                                                                    <p class="description mb-3" v-if="!current_sade_sati">You next sade sati starts from {{ next_sade_sati }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="table-responsive">
                                                            <table class="table kundli-table text-nowrap">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Start Date</th>
                                                                        <th>End Date</th>
                                                                        <th>Sign Name</th>
                                                                        <th>Direction</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="c,i in custom_result.details[5].filter(i => sade_sati_labels.includes(i.type))" :key="i">
                                                                        <td>{{ c.start_date }}</td>
                                                                        <td>{{ c.end_date }}</td>
                                                                        <td>{{ c.zodiac }}</td>
                                                                        <td>{{ c.direction }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="s_type == 4">
                                                        <h4 class="mb-4">Pitra Dosh Analysis</h4>
                                                        <div class="card outline">
                                                            <div class="flex-sc flex-column flex-md-row gap-4">
                                                                <div>
                                                                    <div :class="custom_result.details[9].is_dosha_present ? 'bg-danger px-3' : 'bg-success px-4'" class="rounded-circle py-4 text-white">{{ custom_result.details[9].is_dosha_present ? 'Yes' : 'No' }}</div>
                                                                </div>
                                                                <div>
                                                                    <p class="description mb-3">{{ custom_result.details[9].bot_response }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                                <loader v-else />
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <transition-group name="fade">
            <div class="modal fade" v-if="showModal == 'pdf_kundli'" key="status" :class="{ 'd-block show backdrop' : showModal == 'pdf_kundli'}" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0 overflow-hidden">
                        <div class="modal-card light">
                            <span class="close" @click="showModal = false;"><i class="fas fa-times"></i></span>
                            <form @submit.prevent="create_pdf" class="row">
                                <div class="col-12 form-group orange">
                                    <label>Match Type</label>
                                    <v-select class="w-100" :options="r_types" :clearable="false" v-model="r_type" :reduce="i => i.value">
                                        <template #search="{attributes, events}">
                                            <input class="vs__search" :required="!r_type" v-bind="attributes" v-on="events" />
                                        </template>
                                    </v-select>
                                </div>
                                <div class="col-12">
                                    <button type="submit" :disabled="disBtn == true" class="btn dark block text-nowrap mt-3">
                                        <i v-if="disBtn == true" class="fas fa-spinner fa-spin"></i>
                                        <transition name="flip" mode="out-in">
                                            <span v-if="disBtn == true">Loading . . .</span>
                                            <span v-else>Generate Kundli PDF</span>
                                        </transition>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s ease-in;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
    filter: blur(2px);
}
.slide-enter-active, .slide-leave-active {
    transition: all 0.25s ease-out;
}
.slide-enter-from {
    opacity: 0;
    transform: translateX(100px);
}
.slide-leave-to {
    opacity: 0;
    transform: translateX(-100px);
}
</style>
<script>
import moment from 'moment';
export default {
    props: ['user','pricings'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            showModal: false,
            tab: 0,
            type: 'north',
            charts: ['D1','D2','D3','D4','D7','D9','D10','D10-R','D16','D20','D24','D27','D40','D45','D60','D30','chalit','sun','moon'],
            labels: ["Lagna / Ascendant / Basic Birth Chart","Hora (Wealth / Income Chart)","Drekkana (Relationship with siblings)","Chaturthamsa (Assets)","Saptamsa (Progeny)","Navamsa (Prospects of marriage)","Dasamsa (Profession)","Dwadasamsa (Native parents / Ancestors)","Shodasamsa (Travel)","Vimsamsa (Spiritual progress)","Chaturvimsamsa (Intellectual)","Saptavimsamsa (Strength / Protection)","Khavedamsa (Auspicious time)","Akshavedamsa (General issues)","Shastiamsa (Summary of charts)","Trimsamsa (Misfortunes)","Bhav Chalit","Sun","Moon"],
            types: ['north', 'south'],
            d_type: 1,
            s_type: 1,
            r_types: [{
                value: 'north',
                label: 'North Match / Ashtakoot',
            },{
                value: 'south',
                label: 'South Match / Dashakoot',
            }],
            r_type: '',
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            id: '',
            result: [],
            sade_sati_labels: ['Sade Sati', 'साड़े साती', 'ஏழரை சனி', 'ಸದೇ ಸತಿ', 'ষড়সাতি'],
            next_sade_sati: false,
            subRecords: [],
            subRecordIndex: null,
            data: [],
            loading: false,
            custom_result: false,
            disBtn: false,
            err: false,
       };
    },
    watch: {
        tab(to) {
            this.type = 'north' 
            this.d_type = 1
            this.s_type = 1
        },
    },
    computed: {
        current_sade_sati() {
            var temp = this.custom_result.details[5].filter(i => this.sade_sati_labels.includes(i.type))
            var flag = false
            temp.forEach(e => {
                if(moment(e.start_date, 'ddd MMM DD YYYY').diff(moment()) < 0 && moment(e.end_date, 'ddd MMM DD YYYY').diff(moment()) > 0) {
                    flag = true ;
                }
            });
            if(!flag) {
                temp.every(e => {
                    if(moment(e.end_date, 'ddd MMM DD YYYY').diff(moment()) > 0) {
                        this.next_sade_sati = e.start_date
                        return false ;
                    }
                    return true ;
                });
            }
            return flag ;
        },
        pdf_price() {
            if(this.user.currency.code == 'INR')
                return this.pricings.kundli_pdf_price_INR
            else
                return this.pricings.kundli_pdf_price_USD
        },
    },
    created() {
        if(this.token) {
            this.id = this.$route.params.id
            this.index()
        } else this.loading = true
    },
    methods: {
        index() {
            this.$Progress.start()
            this.loading = true
            axios.post('/api/user/get-kundli-result', {query: this.id, type: 'web'}, this.config).then((res) => {
                this.loading = false
                if(res.data.status == 'success') {
                    this.data = res.data.data
                    this.result = res.data.result
                    this.tab = 1
                    this.custom_result = res.data.result
                    this.$Progress.finish()
                    this.emitter.emit('scroll_section')
                    // setTimeout(() => {
                    //     this.$nextTick(() => {
                    //         this.emitter.emit('animate_background')
                    //     })
                    // }, 500);
                } else {
                    this.err = res.data.message
                    this.$Progress.fail()
                }
                this.$nextTick(() => {
                    // this.emitter.emit('animate_background')
                })
            }).catch((err) => {
                this.$Progress.fail()
                toast.fire({'icon': 'error', 'title': 'Server Error Occured!'})
                this.loading = false
            })
        },
        setSubRecord(type, index) {
            if(type == 'yogini') {
                this.subRecords = this.custom_result.details[12].find((e,v) => v == index)
                if(index == 0) {
                    var tempIndex = []
                    this.subRecords.sub_dasha_end_dates = this.subRecords.sub_dasha_end_dates.filter((i,v) => {
                        if(moment(i).format('YYYY') >= moment(this.data.dob, "DD/MM/YYYY").format('YYYY')) tempIndex.push(v)
                        return moment(i).format('YYYY') >= moment(this.data.dob, "DD/MM/YYYY").format('YYYY') ; 
                    })
                    this.subRecords.sub_dasha_list = this.subRecords.sub_dasha_list.filter((i,v) => tempIndex.includes(v))
                }
                this.subRecordIndex = index
            }
        },
        async create_pdf() {
            if (this.pdf_price != 0) {
                const res = await Swal.fire({
                    icon: 'info',
                    title: 'Kundli PDF is a downloadable version.',
                    text: 'This kundli pdf will cost you ' + this.user.currency.symbol + this.pdf_price,
                    confirmButtonText: 'Proceed',
                });
                if (res.isConfirmed) {
                    if (this.user.wallet_amount - this.pdf_price < 0) {
                        const res2 = await Swal.fire({
                            icon: 'error',
                            title: 'Low Wallet Balance.',
                            text: 'Please recharge your wallet to continue!',
                            confirmButtonText: 'Recharge',
                        });
                        if (!res2.isConfirmed) return;
                        else this.$router.push('/astro-wallet')
                    }
                } else return;
            }
            this.disBtn = true;
            this.$Progress.start();
            try {
                const res = await axios.post('/api/user/kundli-pdf-create', {id: this.id, type: this.r_type}, this.config);
                if (res.data.status == 'success') {
                    this.disBtn = false;
                    this.showModal = false
                    this.$Progress.finish();
                    this.$router.push('/kundli');
                    // window.open(res.data.pdf_url, '_blank').focus();
                    toast.fire({ icon: 'success', title: 'PDF Generated!' });
                } else {
                    this.$Progress.fail();
                    toast.fire({ icon: 'error', title: 'Server Error Occurred!' });
                }
            } catch(e) {
                this.disBtn = false;
                this.$Progress.fail();
                toast.fire({ icon: 'error', title: e.message });
            }
        }
    }
}
</script>