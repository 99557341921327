<template>
<!-- <f_header v-if="language" :language="language" /> -->
<f_header v-show="!header_view" />
<router-view v-slot="{ Component }" :key="user || $route.name" :user="user" :banners="banners" :pandits="pandits" :otp_codes="otp_codes" :currencies="currencies" :skills="skills" :pricings="pricings" :api_langs="api_langs" :astro_langs="astro_langs" :astro_rooms="astro_rooms">
    <section v-if="$route.meta.title" :key="$route.fullPath" class="section orange pt-4 pb-0" ref="section">
        <div class="container">
            <div class="flex-bc" :class="{'flex-column gap-2': mobile}">
                <h5 class="title">{{ $route.meta.title }}</h5>
                <div class="breadcrumb-wrapper">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                        <li class="breadcrumb-item" v-if="$route.meta.link"><router-link :to="$route.meta.link">{{ $route.meta.name }}</router-link></li>
                        <li class="breadcrumb-item active">{{ $route.meta.title }}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
    <!-- <transition  :name="route.meta.transition || 'slide-up'" :mode="route.meta.transition ? '' : 'out-in'"> -->
    <transition  name="slide-up" mode="out-in">
        <keep-alive include="scholarRegistration,MyBookings,Astrologers,Wallet" v-if="user && currencies && skills && api_langs">
            <component :is="Component" :key="$route.params.id || $route.name" />
        </keep-alive>
        <loader :text="loader_text" v-else />
    </transition>
</router-view>
<transition-group name="fade">
    <talk-section key="talk_section" v-if="!hide_talk" :user="user" :pandits="pandits" />
    <blog-section key="blog_section" v-if="!hide_blog" />
    <div class="modal fade" v-if="token == null && showModal == 'login'" key="login" :class="{ 'd-block show backdrop' : showModal=='login'}" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
            <div class="modal-content bg-transparent border-0">
                <div class="modal-card">
                    <span class="close" @click="showModal = false; ltype='mobile'"><i class="fas fa-times"></i></span>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-12">
                                    <div class="text-center">
                                        <img src="/app-assets/images/logo-light.png" alt="App Logo" class="logo">
                                    </div>
                                    <transition name="fade" mode="out-in">
                                        <form v-if="ltype=='mobile'" @submit.prevent="loginSubmit('loginMobile')">
                                            <div class="title">welcome!</div>
                                            <div class="subtitle">greetings from {{ $appname }}.</div>
                                            <div class="form-line"><span>sign in with mobile number</span></div>
                                            <div class="form-group input-group">
                                                <div class="input-group-append">
                                                    <select required class="form-control" v-model="login.country_code">
                                                        <option v-for="(c,index) in otp_codes" :key="index" :value="c">{{ c }}</option>
                                                    </select>
                                                </div>
                                                <input type="tel" maxlength="10" @keypress="validate" placeholder="Enter Mobile Number" minlength="10" v-model="login.mobile" required class="form-control" :class="{'is-invalid' : errors.mobile}">
                                                <span v-if="errors.mobile" class="invalid-feedback"><strong>{{ errors.mobile[0] }}</strong></span>
                                            </div>
                                            <div class="form-group">
                                                <button type="submit" :disabled="disBtn == 1" class="btn block orange-dark text-uppercase">
                                                    <i v-if="disBtn == 1" class="fas fa-spinner fa-spin"></i>
                                                    <transition name="flip" mode="out-in">
                                                        <span v-if="disBtn == 1">Loading . . .</span>
                                                        <span v-else>Send otp</span>
                                                    </transition>
                                                </button>
                                            </div>
                                            <div class="form-line"><span>or</span></div>
                                            <button type="button" @click="ltype='email'" class="btn block outline text-capitalize">continue with email id</button>
                                            <p class="terms">By signing in, you agree to our <br v-if="mobile"> <router-link to="/terms">Terms of Use</router-link>  and  <router-link to="/privacy-policy">Privacy Policy</router-link></p>
                                        </form>
                                        <form v-else-if="ltype == 'mobileotp'" @submit.prevent="verify_otp('mobile')">
                                            <div class="title">OTP Verification</div>
                                            <div class="subtitle">A 4 digit code has been sent to your phone number.</div>
                                            <div class="form-line"><span>Verify Phone Number</span></div>
                                            <div class="form-group">
                                                <div class="flex-cc gap-3">
                                                    <input required type="password" maxlength="1" minlength="1" ref="otp_1" @keypress="validate" @input="onInput($event, 1)" @keydown.backspace="onBackspace($event, 1)" v-model="verify.otp_1" class="form-control otp-input">
                                                    <input required type="password" maxlength="1" minlength="1" ref="otp_2" @keypress="validate" @input="onInput($event, 2)" @keydown.backspace="onBackspace($event, 2)" v-model="verify.otp_2" class="form-control otp-input">
                                                    <input required type="password" maxlength="1" minlength="1" ref="otp_3" @keypress="validate" @input="onInput($event, 3)" @keydown.backspace="onBackspace($event, 3)" v-model="verify.otp_3" class="form-control otp-input">
                                                    <input required type="password" maxlength="1" minlength="1" ref="otp_4" @keypress="validate" @input="onInput($event, 4)" @keydown.backspace="onBackspace($event, 4)" v-model="verify.otp_4" class="form-control otp-input">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <button type="submit" :disabled="disBtn == 2" class="btn block orange-dark text-uppercase">
                                                    <i v-if="disBtn == 2" class="fas fa-spinner fa-spin"></i>
                                                    <transition name="flip" mode="out-in">
                                                        <span v-if="disBtn == 2">Loading . . .</span>
                                                        <span v-else>verify</span>
                                                    </transition>
                                                </button>
                                            </div>
                                            <div class="form-line"><span>or</span></div>
                                            <button type="button" @click="ltype='mobile'; verify.reset();" class="btn block outline text-capitalize">change phone number</button>
                                        </form>
                                        <form v-else-if="ltype=='email'" @submit.prevent="loginSubmit('loginEmail')">
                                            <div class="title">welcome!</div>
                                            <div class="subtitle">greetings from {{ $appname }}.</div>
                                            <div class="form-line"><span>sign in with email ID</span></div>
                                            <div class="form-group">
                                                <input type="email" placeholder="Enter Email ID" v-model="login.email" class="form-control" :class="{'is-invalid' : errors.email}">
                                                <span v-if="errors.email" class="invalid-feedback"><strong>{{ errors.email[0] }}</strong></span>
                                            </div>
                                            <div class="form-group">
                                                <button type="submit" :disabled="disBtn == 3" class="btn block orange-dark text-uppercase">
                                                    <i v-if="disBtn == 3" class="fas fa-spinner fa-spin"></i>
                                                    <transition name="flip" mode="out-in">
                                                        <span v-if="disBtn == 3">Loading . . .</span>
                                                        <span v-else>Send otp</span>
                                                    </transition>
                                                </button>
                                            </div>
                                            <div class="form-line"><span>or</span></div>
                                            <button type="button" @click="ltype='mobile'" class="btn block outline text-capitalize">continue with mobile number</button>
                                            <p class="terms">By signing in, you agree to our <br v-if="mobile">  <router-link to="/terms">Terms of Use</router-link>  and  <router-link to="/privacy-policy">Privacy Policy</router-link></p>
                                        </form>
                                        <form v-else-if="ltype == 'emailotp'" @submit.prevent="verify_otp('email')">
                                            <div class="title">OTP Verification</div>
                                            <div class="subtitle">A 4 digit code has been sent to your email id.</div>
                                            <div class="form-line"><span>Verify Email ID</span></div>
                                            <div class="form-group">
                                                <div class="flex-cc gap-3">
                                                    <input type="password" maxlength="1" minlength="1" ref="otp_1" @keypress="validate" @input="onInput($event, 1)" @keydown.backspace="onBackspace($event, 1)"  v-model="verify.otp_1" class="form-control otp-input">
                                                    <input type="password" maxlength="1" minlength="1" ref="otp_2" @keypress="validate" @input="onInput($event, 2)" @keydown.backspace="onBackspace($event, 2)"  v-model="verify.otp_2" class="form-control otp-input">
                                                    <input type="password" maxlength="1" minlength="1" ref="otp_3" @keypress="validate" @input="onInput($event, 3)" @keydown.backspace="onBackspace($event, 3)"  v-model="verify.otp_3" class="form-control otp-input">
                                                    <input type="password" maxlength="1" minlength="1" ref="otp_4" @keypress="validate" @input="onInput($event, 4)" @keydown.backspace="onBackspace($event, 4)"  v-model="verify.otp_4" class="form-control otp-input">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <button type="submit" :disabled="disBtn == 4" class="btn block orange-dark text-uppercase">
                                                    <i v-if="disBtn == 4" class="fas fa-spinner fa-spin"></i>
                                                    <transition name="flip" mode="out-in">
                                                        <span v-if="disBtn == 4">Loading . . .</span>
                                                        <span v-else>verify</span>
                                                    </transition>
                                                </button>
                                            </div>
                                            <div class="form-line"><span>or</span></div>
                                            <button type="button" @click="ltype='email'; verify.reset();" class="btn block outline text-capitalize">change email id</button>
                                        </form>
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 spin-mandala" v-if="!mobile">
                            <img src="/app-assets/images/mandala.png" alt="Login Mandala" class="mandala">
                            <img src="/app-assets/images/avatar.png" alt="Login Avatar" class="avatar">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" v-if="token == null && showModal == 'status'" key="status" :class="{ 'd-block show backdrop' : showModal=='status'}" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content bg-transparent border-0">
                <div class="modal-card light">
                    <span class="close" @click="showModal = false; ltype='mobile'"><i class="fas fa-times"></i></span>
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="mandala">
                                <img src="/app-assets/images/mandala.png" alt="">
                            </div>
                            <div class="logo">
                                <img src="/app-assets/images/logo-v.png" alt="">
                            </div>
                            <i class="fas fa-check-circle check"></i>
                            <p class="status">Email/Mobile Verification has been successfully completed</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" v-if="showModal == 'language'" key="language" :class="{ 'd-block show backdrop' : showModal== 'language'}" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-modal-dialog-scrollable modal-dialog-centered" role="document">
            <div class="modal-content bg-transparent border-0">
                <div class="features-card rounded-4">
                    <div class="card-header flex-bc">
                        <h3>{{language.select_language}}</h3>
                        <a href="javascript:;" @click="showModal = false;" class="main-button small light"><i class="fas fa-times fa-2x"></i></a>
                    </div>
                    <div class="modal-body">
                        <div class="row contact-form">
                            <ul class="mb-0">
                                <label v-for="l in languages" :key="l.id" class="radio-container" @click="update_lang(l)">{{l.name}}
                                    <input type="radio" :checked="l.code == lang() ? true : false" name="radio">
                                    <span class="checkmark"></span>
                                </label>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" v-if="showModal == 'feedback'" :class="{ 'd-block show backdrop' : showModal}" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content bg-transparent border-0">
                <form @submit.prevent="submit_feedback" class="features-card rounded-4">
                    <div class="card-header flex-bc">
                        <h3>{{language.tooltip_feedback}}</h3>
                        <a href="javascript:;" @click="showModal = false;" class="main-button small light"><i class="fas fa-times fa-2x"></i></a>
                    </div>
                    <div class="modal-body border-bottom">
                        <div class="row contact-form">
                            <div class="col-12 col-sm-6">
                                <label class="ms-3 mb-1">{{language.name}}</label>
                                <input type="text" class="form-control" :class="{'is-invalid mb-0' : errors.name}" required v-model="feedback.name">
                                <span class="invalid-feedback ms-2" v-if="errors.name"><strong>{{ errors.name[0] }}</strong></span>
                            </div>
                            <div class="col-12 col-sm-6">
                                <label class="ms-3 mb-1">{{language.mobile}}</label>
                                <input type="tel" class="form-control" :class="{'is-invalid mb-0' : errors.mobile}" maxlength="10" v-model="feedback.mobile">
                                <span class="invalid-feedback ms-2" v-if="errors.mobile"><strong>{{ errors.mobile[0] }}</strong></span>
                            </div>
                            <div class="col-12 col-sm-6">
                                <label class="ms-3 mb-1">{{language.email}}</label>
                                <input type="email" class="form-control" :class="{'is-invalid mb-0' : errors.email}" required v-model="feedback.email">
                                <span class="invalid-feedback ms-2" v-if="errors.email"><strong>{{ errors.email[0] }}</strong></span>
                            </div>
                            <div class="col-12 col-sm-3">
                                <label class="ms-3 mb-1">{{language.click_to_refresh}}</label>
                                <img :src="captcha_src" @click="captcha_src = '/get/captcha?' + (new Date()).getTime()" class="rounded-5 w-100 cursor-pointer" height="50">
                            </div>
                            <div class="col-12 col-sm-3">
                                <label class="ms-3 mb-1">{{language.captcha}}</label>
                                <input type="text" class="form-control" :class="{'is-invalid mb-0' : errors.captcha}" required v-model="feedback.captcha">
                                <span class="invalid-feedback ms-2" v-if="errors.captcha"><strong>{{ errors.captcha[0] }}</strong></span>
                            </div>
                            <div class="col-12">
                                <label class="ms-3 mb-1">{{language.message}}</label>
                                <textarea v-model="feedback.message" class="form-control" :class="{'is-invalid mb-0' : errors.message}" required></textarea>
                                <span class="invalid-feedback ms-2" v-if="errors.message"><strong>{{ errors.message[0] }}</strong></span>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer pt-3 text-center">
                        <button type="button" class="main-button light" @click="showModal = false;">{{language.close}}</button>
                        <button type="submit" class="main-button ms-4">{{language.send_message}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal fade" v-if="showModal == 'AVModal'" key="status" :class="{ 'd-block show backdrop' : showModal == 'AVModal'}" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content bg-transparent border-0 overflow-hidden">
                <div class="modal-card light">
                    <span class="close" @click="showModal = false;"><i class="fas fa-times"></i></span>
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="logo">
                                <img src="/app-assets/images/logo-v.png" alt="">
                            </div>
                            <p class="status" style="text-transform: none;">To enjoy our live-streaming, audio and video calling capabilities, please download our mobile app.<br><br>Available on both Android and iOS, our app provides a seamless and optimized communication experience.</p>
                            <div class="flex-cc gap-5 mt-4">
                                <router-link to="#" class="text-primary">
                                    <img src="/app-assets/images/iOSAppDownloadIcon.png" alt="iOS App Download Button" class="img-fluid">
                                </router-link>
                                <router-link to="#" class="text-primary">
                                    <img src="/app-assets/images/GooglePlayAppDownloadIcon.png" alt="Google Play App Download Button" class="img-fluid">
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button v-if="isScrollTopVisible" class="btn orange-light scroll-top-button" type="button" @click="scollTop()">
        <i class="fas fa-long-arrow-up me-0"></i>
    </button>
    <div class="cookie-info" v-if="showCookiePrompt">
        <div class="container">
            <div class="d-flex justify-content-between flex-column flex-md-row align-items-center">
                <div class="text pe-md-5 text-center text-md-start">The website uses cookies to offer you essential features and increased performance. Read more in the
                    <a :href="$domain+'/cookie-policy'" target="_blank" class="href-primary">Cookies Policy</a> . If you agree to the usage of performance cookies, click OK.
                </div>
                <div class="pt-2 pt-md-0">
                    <button class="btn orange-dark rounded-4" @click="cookie"><b>OK</b></button>
                </div>
            </div>
        </div>
    </div>
</transition-group>
<vue-progress-bar></vue-progress-bar>
<!-- <f_footer v-if="language" :language="language" /> -->
<f_footer :policies="policies" />
</template>
<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
        filter: blur(2px);
    }
    .grow-enter-active, .grow-leave-active {
        transition: all 0.25s ease-out;
    }
    .grow-enter-from {
        opacity: 0;
        filter: blur(15px);
        transform: scale(0);
        transform-origin: top left;
    }
    .grow-leave-to {
        opacity: 0;
        filter: blur(15px);
        transform: scale(0);
        transform-origin: top right;
    }
    .slide-up-enter-active, .slide-up-leave-active {
        transition: all 0.25s ease-out;
    }
    .slide-up-enter-from {
        opacity: 0;
        filter: blur(15px);
        transform: translateY(30px);
    }
    .slide-up-leave-to {
        opacity: 0;
        filter: blur(15px);
        transform: translateY(-30px);
    }
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.5s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.03);
            margin-bottom: 60px;
        }
        100% {
            transform: scale(1);
        }
    }
    .slide-left-enter-active, .slide-left-leave-active {
        transition: all 0.25s ease-out;
    }
    .slide-left-enter-from {
        opacity: 0;
        transform: translateX(100px);
    }
    .slide-left-leave-to {
        opacity: 0;
        transform: translateX(-100px);
    }
</style>
<script setup>
    import f_footer from "./partials/footer.vue" ;
    import f_header from "./partials/header.vue" ;
    import VueGoogleAutocomplete from "vue-google-autocomplete";
    import { getToken } from "firebase/messaging";
</script>
<script>
export default {
    components : { VueGoogleAutocomplete },
    props: ['header_view'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            user: false,
            language: false,
            languages: [],
            banners: [],
            currencies: false,
            skills: false,
            pricings: false,
            policies: [],
            otp_codes: [],
            api_langs: [],
            astro_langs: [],
            astro_rooms: [],
            errors: [],
            loader_text: 'Loading . . .',
            categories: false,
            pandits: [
                {
                    id: 30,
                    name:'Rohit Sharma',
                    photo:'' ,
                    audio_price: 100 ,
                    video_price: 50 ,
                    chat_price: 30 ,
                },
                {
                    id:2,
                    name:'Anurag Yadav',
                    photo:'' ,
                    audio_price: 100 ,
                    video_price: 50 ,
                    chat_price: 20 ,
                },
                {
                    id:3,
                    name:'Ashutosh Bakshi',
                    photo:'' ,
                    audio_price: 100 ,
                    video_price: 50 ,
                    chat_price: 50 ,
                },
            ],
            flag: false,
            focus: false,
            add_emit: false,
            hide_talk: false,
            hide_blog: false,
            save_location: false,
            redirectUrl: '',
            showModal: false,
            showClose: true,
            add_url: '/p/add',
            isScrollTopVisible: false,
            disabled: false,
            ltype: 'mobile',
            psShow: false,
            disBtn: false,
            autocompleteFields : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity'],
            login: new Form({
                mobile: '',
                email: '',
                otpHash: '',
                country_code: '+91',
            }),
            verify: new Form({
                otp_1: '',
                otp_2: '',
                otp_3: '',
                otp_4: '',
            }),
            feedback: new Form({
                name:'',
                email:'',
                mobile:'',
                captcha:'',
                message:'',
            }),
            current_lang: {
                code: '',
                name: ''
            },
            showCookiePrompt: false,
            captcha_src: '/get/captcha',
        };
    },
    computed: {
        classes() {
            if(this.focus) return 'form-control mb-3 focus'
            else return 'form-control mb-3'
        }
    },
    async created() {
        var check = localStorage.getItem('storage_enabled')
        if(check == null || !check) this.showCookiePrompt = true

        if(this.token == null) this.user = []
        this.app_data()

        var m_check = localStorage.getItem('messaging')
        if(m_check == null || !m_check) this.fcm_token()
    },
    watch: {
        with_email(n, o) {
            if(n) {
                this.$nextTick(() => {
                    this.$refs.login_email.focus()
                })
            }
        }
    },
    updated() {
        $('[data-toggle="tooltip"]').tooltip('dispose')
        this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable'))
    },
    mounted() {
        this.emitter.on("update_user", data => {
            if(!data.completed) {
                this.$router.push('/my-profile'); 
            }
            if(!this.user) {
                this.$nextTick(()=> {
                    document.querySelector('body').scrollIntoView({behavior: 'smooth'})
                })
            }
            this.user = data
        });
        const redirectUrl = new URLSearchParams(window.location.search).get('mall-login');
        if (redirectUrl && this.token == null) {
            this.redirectUrl = redirectUrl;
            this.showModal = 'login' ;
            this.showClose = false
        }
        this.emitter.on("fetch_lang", () => {
            this.app_data()
        });
        this.emitter.on('showAVmodal', (data = null) => {
            this.showModal = 'AVModal' ;
        });
        this.emitter.on('showlogin', (data = null) => {
            this.showModal = 'login' ;
            this.showClose = data ? false : true
        })
        this.emitter.on('lang_modal', (data) => {
            this.languages = data
            this.showModal = 'language' ;
        })
        this.emitter.on('copy_url', (url) => {
            this.copyUrl(url) ;
        })
        this.emitter.on('animate_background', () => {
            this.animateBackground() ;
        })
        this.emitter.on("loggedOut", () => { this.token = null ; this.user = [] ; });

        this.emitter.on('talk_section', (boolean) => [
            this.hide_talk = boolean
        ])
        this.emitter.on('blog_section', (boolean) => [
            this.hide_blog = boolean
        ])
        this.emitter.on('scroll_section', () => {
            this.$refs.section.scrollIntoView({behavior: 'smooth'})
        })
        window.addEventListener('scroll', this.handleScrollTop);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScrollTop);
    },
    updated() {
        this.emitter.on("loggedOut", () => { this.token = null ; this.user = [] ; });
        this.emitter.on("product_add", (url) => {
            this.add_url = url ;
        })
    },
    methods: {
        async fcm_token() {
            await getToken(this.$messaging, {vapidKey: import.meta.env.VITE_VAPID_KEY}).then((currentToken) => {
                if (currentToken) {
                    localStorage.setItem('messaging', currentToken)
                    this.save_fcm_token()
                } else {
                    Notification.requestPermission().then((permission) => {
                        if (permission === 'granted') {
                            localStorage.setItem('messaging', currentToken)
                            this.save_fcm_token()

                        } else if (result.state === 'prompt') {
                            localStorage.setItem('messaging', currentToken)
                            this.save_fcm_token()
                        }
                        else{
                            Swal.mixin({ toast: true, position: 'top-start', showConfirmButton: false, timer: 4000 }).fire({
                                icon: "warning",
                                title: 'Permission denied.',
                            });
                        }
                    });
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
        },
        async save_fcm_token() {
            var m_check = localStorage.getItem('messaging')
            await axios.post('/api/user/save-fcm-token', {token: m_check, device_type: 3}).then((res) => {
                console.log('Firebase Token Saved!');
            })
        },
        async app_data() {
            await axios.get('/api/user/app-data').then((res) => {
                this.currencies = res.data.currencies
                this.skills = res.data.skills
                this.pricings = res.data.pricings
                this.astro_langs = res.data.astro_langs
                this.astro_rooms = res.data.astro_rooms
                this.api_langs = res.data.api_langs
                this.banners = res.data.banners
                this.otp_codes = res.data.otp_codes
                this.policies = res.data.policies
            }).catch((e) => { console.log(e); });
        },
        loginSubmit(type) {
            if(type == 'loginEmail') {
                this.disBtn = 3
                this.$Progress.start()
                axios.post('/api/user/login', {type: this.ltype, email: this.login.email}).then((res) => {
                    this.$Progress.finish()
                    this.disBtn = false
                    if(res.data.status == 'error') {
                        toast.fire({'icon': 'error', 'title': res.data.message}) ;
                        return ;
                    }
                    this.ltype = 'emailotp'
                    this.login.otpHash = res.data.otpHash
                }).catch((err) => {
                    this.disBtn = false
                    this.$Progress.fail()
                });
            }
            else {
                this.disBtn = 1
                this.$Progress.start()
                axios.post('/api/user/login', {type: this.ltype, mobile: this.login.mobile, country_code: this.login.country_code}).then((res) => {
                    this.$Progress.finish()
                    this.disBtn = false
                    if(res.data.status == 'error') {
                        toast.fire({'icon': 'error', 'title': res.data.message}) ;
                    }
                    else {
                        this.ltype = 'mobileotp'
                        this.login.otpHash = res.data.otpHash
                    }
                }).catch((err) => {
                    this.$Progress.fail()
                    this.disBtn = false
                });
            }
        },
        verify_otp(type) {
            if(type == 'mobile') {
                this.disBtn = 2
                this.$Progress.start()
                var fcm_token = localStorage.getItem('messaging')
                var data = {
                    fcm_token: fcm_token,
                    otpHash: this.login.otpHash,
                    verifyOtp: this.verify,
                    type: type,
                    mobile: this.login.mobile,
                }
                axios.post('/api/user/verify-login-otp', data).then((res) => {
                    this.disBtn = false
                    if(res.data.status == 'success') {
                        this.showModal = false;
                        this.$Progress.finish()
                        this.ltype = 'mobile'
                        this.token = res.data.token 
                        localStorage.setItem("userLogin", res.data.token);
                        this.login.reset()
                        this.verify.reset()
                        if(
                            this.redirectUrl != '' && 
                            (this.redirectUrl == this.$domain.replace('://', '://mall.') ||
                            this.redirectUrl == this.$mall_domain)
                        ) window.location.href = `${this.redirectUrl}?token=${this.token}`;
                        if(this.redirectUrl != '' && this.redirectUrl != this.$domain.replace('://', '://mall.')) toast.fire({icon: 'error', message: 'Invalid Redirect URL!'})
                        else this.emitter.emit("loggedIn", res.data.token);
                        if(!res.data.completed) { setTimeout(() => { this.$router.push('/my-profile'); }, 500); }
                    }
                    else {
                        this.$Progress.fail()
                        toast.fire({icon: 'error', title: res.data.message})
                    }
                }).catch((err) => {
                    this.$Progress.fail()
                    this.disBtn = false
                });
            }
            else {
                this.disBtn = 4
                this.$Progress.start()
                var fcm_token = localStorage.getItem('messaging')
                var data = {
                    fcm_token: fcm_token,
                    otpHash: this.login.otpHash,
                    verifyOtp: this.verify,
                    type: type,
                }
                axios.post('/api/user/verify-login-otp', data).then((res) => {
                    this.$Progress.finish()
                    this.disBtn = false
                    localStorage.setItem("userLogin", res.data.token);
                    this.emitter.emit("loggedIn", res.data.token);
                    this.showModal = false;
                    this.verify.reset()
                    this.ltype = 'mobile'
                    this.token = res.data.token
                    if(!res.data.completed) { setTimeout(() => { this.$router.push('/my-profile'); }, 500); }
                }).catch((err) => {
                    this.$Progress.fail()
                    this.disBtn = false
                });
            }
        },
        cookie() {
            localStorage.setItem('storage_enabled',true)
            this.showCookiePrompt = false
        },
        update_lang(l){
            this.current_lang.name = l.name
            this.current_lang.code = l.code
            var expires = "";
            var date = new Date();
            date.setTime(date.getTime() + (30*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
            document.cookie = "locale=" + (l.code || "")  + expires + "; path=/";
            this.emitter.emit("fetch_lang")
            this.emitter.emit("updated_lang", l)
            this.showModal = false
        },
        copyToClipboard(text) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                return navigator.clipboard.writeText(text);
            } else {
                var textArea = document.createElement("textarea");
                textArea.value = text;
                textArea.style.position = "fixed"; // Ensure it is invisible
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    var successful = document.execCommand("copy");
                    var msg = successful ? "successful" : "unsuccessful";
                } catch (err) {
                    console.error("Fallback: Unable to copy to clipboard", err);
                }

                document.body.removeChild(textArea);
                return Promise.resolve();
            }
        },
        copyUrl(url) {
            // navigator.clipboard.writeText(url);
            this.copyToClipboard(url).then(() => {
                toast.fire({
                    'icon': 'success',
                    'title': 'Copied to Clipboard 👍',
                })
            })
            .catch((err) => { console.error("Failed to copy URL to clipboard:", err); });
        },
        show_feedback() {
            if(this.token) {
                this.captcha_src = '/get/captcha?' + (new Date()).getTime()
                this.showModal = 'feedback'
            }
            else {
                toast.fire({ 'icon': 'info', 'title': this.language.toast_login_feedback})
                this.showModal = 'login'
            }
        },
        onInput(event, index) {
            const nextInput = this.$refs[`otp_${index + 1}`];
            if (event.target.value.length === 1 && nextInput) {
                nextInput.focus();
            }
        },
        onBackspace(event, index) {
            const prevInput = this.$refs[`otp_${index - 1}`];
            if (event.target.value.length === 0 && prevInput) {
                prevInput.focus();
            }
        },
        animateBackground() {
            const animatedBackground = document.querySelectorAll('.animated-background');
            document.body.addEventListener('mousemove', (event) => {
                const { clientX, clientY } = event;
                animatedBackground.forEach((e,v) => {
                    const { left, top, width, height } = e.getBoundingClientRect();
                    const xOffset = clientX - left - 150;
                    const yOffset = clientY - top - 150 ;
                    e.style.setProperty('--x-offset', `${xOffset}px`);
                    e.style.setProperty('--y-offset', `${yOffset}px`);
                })
            });
        },
        handleScrollTop() {
            this.isScrollTopVisible = window.scrollY > 400;
        },
        scollTop() {
            document.body.scrollIntoView({top: 0, behaviour: 'smooth'})
        },
    }
}
</script>
